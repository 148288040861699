































import { LatLng } from '@/types'
import { Component, Vue } from 'vue-property-decorator'
import { GoogleMapApi } from '@/utils/api'

@Component({
  components: {}
})
export default class Map extends Vue {
  center: LatLng = {
    lat: 36.389864680963164,
    lng: 139.05982097143308
  }
  markerPosition: LatLng = { ...this.center }
  markerAddress = ''
  zoom = 15
  showedMenu = false
  x = 0
  y = 0

  created(): void {
    document.title = '地点付近の地図'
  }

  mounted(): void {
    console.log('mounted')
    const arr = this.$route.params.latLng.split(',')
    if (arr.length === 2) {
      this.center = {
        lat: parseFloat(arr[0]),
        lng: parseFloat(arr[1])
      }
      this.markerPosition = { ...this.center }
    }
  }

  place(event: any): void {
    if (event) {
      var lat = event.latLng.lat()
      var lng = event.latLng.lng()
      this.markerPosition = {
        lat: lat,
        lng: lng
      }
      console.log(this.markerPosition)
    }
  }

  async showMenu(e: any) {
    e.domEvent.preventDefault()
    this.showedMenu = false
    this.x = e.domEvent.clientX
    this.y = e.domEvent.clientY
    this.$nextTick(() => {
      this.showedMenu = true
    })
    const res = await GoogleMapApi.reverseGeocoding(this.markerPosition)
    this.markerAddress = res
  }

  fire(): void {
    if (window.opener === undefined || window.opener == null) {
      return
    }
    window.opener.loadMapPoints(this.markerAddress, this.markerPosition.lat, this.markerPosition.lng)
    setTimeout(() => {
      window.close()
      window.opener.focus()
    }, 500)
  }
}
