



































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { Common } from '@/utils/common'
import app from '@/store/app'

@Component({
  components: {
    //
  }
})
export default class extends Vue {
  @Prop({ default: '' }) private mail!: string
  @Prop({ default: '' }) private cc!: string

  address = ''
  dialog = false
  maxUserLength = 20

  @Emit('cc-changed')
  public changed(cc: string): string {
    return cc
  }

  get emails(): string[] {
    if (this.cc === undefined || this.cc === '') {
      return []
    } else {
      return this.cc.split(',')
    }
  }

  showDialog(): void {
    this.address = ''
    this.dialog = true
  }

  add(): void {
    this.address = this.address.trim()
    if (this.address === '') {
      return
    }
    if (this.emails.length >= this.maxUserLength - 1) {
      app.setSnackbar(`これ以上登録できません(To/CC: ${this.maxUserLength}ユーザー)`)
      return
    }
    if (Common.validEmail(this.address) !== true) {
      app.setSnackbar('メールアドレスが不正です')
      return
    }
    if (this.address === this.mail) {
      app.setSnackbar('メールアドレスが重複しています')
      return
    }
    if (this.emails.includes(this.address) === true) {
      app.setSnackbar('メールアドレスが重複しています')
      return
    }

    const changedEmails = [...this.emails]
    changedEmails.push(this.address)
    this.changed(changedEmails.length === 0 ? '' : changedEmails.join(','))

    this.address = ''
    this.dialog = false
  }

  remove(email: string): void {
    const emails = this.emails.filter((m: string) => m !== email)
    this.changed(emails.length === 0 ? '' : emails.join(','))
  }
}
