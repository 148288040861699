





































































































































import { Component, Vue } from 'vue-property-decorator'
import { Api } from '@/utils/api'
import { Common } from '@/utils/common'
import { Destination } from 'gnm-pref-report-shared'
import SortBtn from '@/components/SortBtn.vue'
import app from '@/store/app'
import { SortParam } from '@/types'
import CcEditor from '@/components/CcEditor.vue'

@Component({
  components: {
    SortBtn,
    CcEditor
  }
})
export default class extends Vue {
  baseRows: Destination[] = []
  rows: Destination[] = []
  target: Destination | null = null
  dialog = false
  sortParam: SortParam = {
    field: '名称',
    desc: false
  }
  searchText = ''
  section: 'stage' | 'dam' = 'stage'
  sections = [
    { id: 'stage', name: '水位到達・水防警報' },
    { id: 'dam', name: 'ダム放流通知' }
  ]
  isAddMode = false

  created(): void {
    this.clear()
    app.setSortField('名称')
  }

  mounted(): void {
    if (this.$route.params.section === 'dam') {
      this.section = 'dam'
    } else {
      this.section = 'stage'
    }
    this.sync()
  }

  clear(): void {
    this.target = null
  }

  cancel(): void {
    this.clear()
    this.dialog = false
  }

  async sync(): Promise<void> {
    this.clear()
    this.rows = []
    const res = await Api.get(`/destination/${this.section}`)
    if (res === null) {
      return
    }
    res.forEach((dest: Destination) => {
      if (dest.practiceMode === undefined) {
        dest.practiceMode = false
      }
      if (dest.cc === undefined) {
        dest.cc = ''
      }
    })
    this.baseRows = res
    this.search()
  }

  edit(row: Destination): void {
    this.isAddMode = false
    this.target = { ...row }
    this.dialog = true
  }

  add(): void {
    this.isAddMode = true
    const ids = this.baseRows.map((d: Destination) => Number(d.id))
    const maxId = Math.max(...ids) + 1
    this.target = {
      id: `${maxId}`,
      name: '',
      mail: '',
      cc: '',
      fax: '',
      tel: '',
      suigaiSysId: '',
      practiceMode: true
    }
    this.dialog = true
  }

  validEmail(val: string): boolean {
    const pattern = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/
    return pattern.test(val)
  }

  ccChanged(cc: string): void {
    if (this.target !== null) {
      this.target.cc = cc
    }
  }

  async save(): Promise<void> {
    if (this.target === null) {
      return
    }
    if (
      this.target.id === '' ||
      isNaN(Number(this.target.id)) === true ||
      Number(this.target.id) > 999 ||
      Number(this.target.id) <= 0
    ) {
      alert('IDを3桁の数値で入力してください')
      return
    }
    if (this.target.name === '') {
      alert('名称が未入力です')
      return
    }
    if (this.target.mail !== '' && this.validEmail(this.target.mail) !== true) {
      alert('メールアドレスが不正です')
      return
    }
    if (this.target.mail === '' && this.target.cc !== undefined && this.target.cc !== '') {
      alert('メールアドレスを入力して下さい(CCのみは不可)')
      return
    }
    const params = {
      isAddMode: this.isAddMode,
      target: this.target
    }
    const res = await Api.put(`/destination/${this.section}`, params)
    if (res === null) {
      return
    }
    if (res.error !== undefined) {
      app.setSnackbar(res.error)
    } else {
      this.dialog = false
      this.sync()
    }
  }

  async del(row: Destination): Promise<void> {
    if (row.id === '') {
      return
    }
    if (confirm(`${row.name}を削除します。\nよろしいですか？`) !== true) {
      return
    }
    const res = await Api.delete(`/destination/${this.section}/${row.id}`)
    if (res !== null) {
      this.sync()
    }
  }

  sort(sortEvent: SortParam): void {
    this.sortParam = sortEvent
    const order = [{ key: 'name', reverse: sortEvent.desc }]
    this.rows.sort(Common.compareHelper(order))
  }

  search(): void {
    this.rows = this.baseRows.filter((row: Destination) => {
      return row.name.indexOf(this.searchText) >= 0
    })
    this.sort(this.sortParam)
  }
}
