








import { Component, Vue, Prop } from 'vue-property-decorator'
import { VueLoading } from 'vue-loading-template'

@Component({
  components: {
    VueLoading
  }
})
export default class extends Vue {
  @Prop()
  isLoading!: boolean

  size = {
    width: '60px',
    height: '60px'
  }
}
