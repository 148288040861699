










































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Consts } from '@/utils/consts'
import auth from '@/store/auth'
import app from '@/store/app'
import Loading from '@/components/Loading.vue'

@Component({
  components: {
    Loading
  }
})
export default class extends Vue {
  title = Consts.APP_TITLE
  drawer = false
  items = [
    { title: '水位到達情報・水防警報', icon: 'mdi-text-box-outline', to: '/stage/execution' },
    { title: 'ダム放流通知', icon: 'mdi-text-box-outline', to: '/dam/execution' },
    { title: '氾濫地点一覧', icon: 'mdi-map-marker', to: '/flood' },
    { title: '発表先一覧', icon: 'mdi-account-multiple', to: '/destination/stage', isKasenkaOnly: true },
    { title: '連絡系統', icon: 'mdi-sitemap-outline', to: '/destination-tree', isKasenkaOnly: true }
  ]
  mini = true
  snackbar = {
    visible: false,
    text: '',
    timeout: 3000
  }

  @Watch('appSnackbar')
  showSnackbar(text: string): void {
    if (text === '' || this.snackbar.visible === true) {
      return
    }
    this.snackbar.text = text
    this.snackbar.visible = true
    setTimeout(() => {
      app.setSnackbar('')
    }, this.snackbar.timeout)
  }

  get accountName(): string {
    if (auth.account === null) {
      return ''
    } else {
      return auth.account.name
    }
  }

  get appSnackbar(): string {
    return app.snackbar
  }

  get visibleBar(): boolean {
    return auth.signed && this.$route.name !== 'Map'
  }

  get loading(): boolean {
    return app.loading
  }

  get isKasenka(): boolean {
    return auth.isKasenka
  }

  get isStage(): boolean {
    return auth.isStage
  }

  get isDam(): boolean {
    return auth.isDam
  }

  created(): void {
    document.title = this.title
  }

  logout(): void {
    auth.logout()
    this.$router.replace('/login')
  }

  openManual(): void {
    const url = this.$route.path.includes('dam') ? 'https://youtu.be/h3GURiEN-64' : 'https://youtu.be/eky21Zm5H9U'
    window.open(url)
  }

  openPdfManual(): void {
    const url = this.$route.path.includes('dam')
      ? 'https://www.gunma-suibou.jp/manual/dam.pdf'
      : 'https://www.gunma-suibou.jp/manual/stage.pdf'
    window.open(url)
  }
}
