import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import auth from '@/store/auth'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/mail-received',
    name: 'MailReceived',
    component: () => import('../views/MailReceived.vue')
  },
  {
    path: '/stage/:mode',
    name: 'Stage',
    component: () => import('../views/Stage.vue')
  },
  {
    path: '/stage/:mode/report/:osid',
    name: 'StageReport',
    component: () => import('../views/StageReport.vue')
  },
  {
    path: '/stage/:mode/history/:osid',
    name: 'StageHistory',
    component: () => import('../views/StageHistory.vue')
  },
  {
    path: '/flood',
    name: 'Flood',
    component: () => import('../views/Flood.vue')
  },
  {
    path: '/destination/:section',
    name: 'Destination',
    component: () => import('../views/Destination.vue')
  },
  {
    path: '/destination-tree',
    name: 'DestinationTree',
    component: () => import('../views/DestinationTree.vue')
  },
  {
    path: '/map/:latLng',
    name: 'Map',
    component: () => import('../views/Map.vue')
  },
  {
    path: '/dam/:mode',
    name: 'Dam',
    component: () => import('../views/Dam.vue')
  },
  {
    path: '/dam/:mode/report/:osid',
    name: 'DamReport',
    component: () => import('../views/DamReport.vue')
  },
  {
    path: '/dam/:mode/history/:osid',
    name: 'DamHistory',
    component: () => import('../views/DamHistory.vue')
  },
  {
    path: '/mlit/top/:section',
    name: 'MlitTop',
    component: () => import('../views/MlitTop.vue')
  },
  {
    path: '/mlit/report/:section/:timestamp',
    name: 'MlitReport',
    component: () => import('../views/MlitReport.vue')
  },
  {
    path: '/mlit/history/:section/:osid',
    name: 'MlitHistory',
    component: () => import('../views/MlitHistory.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.name === 'Login' || to.name === 'MailReceived' || auth.signed) {
    next()
  } else {
    const hasSession = await auth.hasSession()
    if (hasSession === true) {
      next()
    } else {
      next({ name: 'Login' })
    }
  }
})

router.afterEach(async () => {
  if (auth.signed && auth.account === null) {
    await auth.getAccountInfo()
  }
})

export default router
