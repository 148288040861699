export class Consts {
  static readonly APP_TITLE = '水防警報等支援システム'
  static readonly API_BASE_URL = {
    development: 'https://t64pre4m81.execute-api.ap-northeast-1.amazonaws.com/dev/api',
    production: `${window.origin}/api`
  }
  static readonly X_API_KEY = 'sFbc9GSf4f6n1PHOGZoHb9SWSkqOBhYpax8RTBOq'
  static readonly SESSION_KEY_TOKEN = 'ska'
  static readonly APP_BAR_HEIGHT = 96
  static readonly GOOGLE_MAP_KEY = 'AIzaSyAY_HbIKXUPe3ORNee_r1INgg-3UqioQsM'
  static readonly S3_PUBLIC_URL = process.env.NODE_ENV === 'production' ? window.origin : 'https://gunma-suibou.jp'
  static readonly MODE = {
    execution: 'execution',
    practice: 'practice'
  }
}
