

















import { Component, Vue } from 'vue-property-decorator'
import { Consts } from '@/utils/consts'
import { Api } from '@/utils/api'

@Component
export default class MailReceived extends Vue {
  title = Consts.APP_TITLE
  message = 'Loading...'

  async mounted(): Promise<void> {
    try {
      const p1 = this.$route.query.p1
      const buffer = window.atob(p1 as string)
      const params = JSON.parse(buffer)
      let res = null
      if (params.mlit !== undefined && Number(params.mlit) === 1) {
        res = await Api.post('/mlit/mail-received', params)
      } else {
        res = await Api.post('/mail-received', params)
      }
      if (res !== null && res.received === true) {
        if (res.duplicate === true) {
          this.message = '受信確認は送信済みです。'
        } else {
          this.message = 'メールの受信確認を送信しました。'
        }
      } else {
        this.message = 'Error.'
      }
    } catch (e) {
      this.message = 'Parse Error.'
      console.log(e)
    }
  }
}
