














import { Component, Vue, Prop } from 'vue-property-decorator'
import { Observatory } from 'gnm-pref-report-shared'

@Component
export default class ObservatoryHeader extends Vue {
  @Prop({ default: '/stage/execution' })
  backTo!: string

  @Prop()
  observatory!: Observatory

  back(): void {
    this.$router.replace(this.backTo)
  }
}
