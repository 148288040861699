import { Mutation, Action, VuexModule, getModule, Module } from 'vuex-module-decorators'
import store from '@/store'
import { Api } from '@/utils/api'
import { MlitSection, MlitObservatory } from '@/types'

@Module({
  dynamic: true,
  store,
  name: 'mlit',
  namespaced: true
})
class Mlit extends VuexModule {
  private _stages: MlitObservatory[] = []
  private _dams: MlitObservatory[] = []

  public get stages(): MlitObservatory[] {
    return this._stages
  }

  public get dams(): MlitObservatory[] {
    return this._dams
  }

  @Mutation
  setStages(rows: MlitObservatory[]) {
    this._stages = rows
  }

  @Mutation
  setDams(rows: MlitObservatory[]) {
    this._dams = rows
  }

  @Action
  public async fetch(section: MlitSection): Promise<MlitObservatory[]> {
    if (section === 'stage' && this._stages.length > 0) {
      return this._stages
    } else if (section === 'dam' && this._dams.length > 0) {
      return this._dams
    }

    const res = await Api.get(`/mlit/observatories/${section}`)
    if (res === null) {
      return []
    }
    if (section === 'stage') {
      this.setStages(res)
      return this._stages
    } else if (section === 'dam') {
      this.setDams(res)
      return this._dams
    } else {
      return []
    }
  }

  @Action
  public async refetch(section: MlitSection): Promise<void> {
    if (section === 'stage') {
      this._stages = []
    } else if (section === 'dam') {
      this._dams = []
    }
    await this.fetch(section)
  }
}
export default getModule(Mlit)
