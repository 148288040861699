














































































































































import { Component, Vue } from 'vue-property-decorator'
import { Api } from '@/utils/api'
import { Common } from '@/utils/common'
import { FloodPoint } from 'gnm-pref-report-shared'
import SortBtn from '@/components/SortBtn.vue'
import app from '@/store/app'
import { SortParam } from '@/types'

@Component({
  components: {
    SortBtn
  }
})
export default class Flood extends Vue {
  baseRows: FloodPoint[] = []
  rows: FloodPoint[] = []
  flood: FloodPoint | null = null
  dialog = false
  sortParam: SortParam = {
    field: 'id',
    desc: true
  }
  readonly FILTER_NONE = '全て'
  rivers: string[] = []
  river = this.FILTER_NONE
  osname = ''

  created(): void {
    this.clear()
    if (this.$route.query.osname !== undefined && typeof this.$route.query.osname === 'string') {
      this.osname = this.$route.query.osname
    }
    app.setSortField('識別番号')
  }

  mounted(): void {
    this.sync()
  }

  clear(): void {
    this.flood = {
      id: '',
      river: '',
      osid: '',
      osname: '',
      address: '',
      lr: '',
      distMark: '',
      lat: '',
      lng: '',
      place: '',
      breakWidth: '',
      summary: '',
      updated: ''
    }
  }

  cancel(): void {
    this.clear()
    this.dialog = false
  }

  async sync(): Promise<void> {
    this.clear()
    this.rows = []
    this.baseRows = []
    const res = await Api.get('/flood')
    if (res === null) {
      return
    }

    if (this.rivers.length === 0) {
      this.rivers = res.map((row: FloodPoint) => (row.river === undefined ? '' : row.river))
      this.rivers = Array.from(new Set(this.rivers))
      this.rivers.unshift(this.FILTER_NONE)
    }
    this.baseRows = res
    this.search()
  }

  append(): void {
    this.clear()
    this.dialog = true
  }

  edit(row: FloodPoint): void {
    this.flood = { ...row }
    this.dialog = true
  }

  async save(): Promise<void> {
    if (this.flood === null) {
      return
    }

    if (this.flood.place === '') {
      alert('破堤地点名称は必須項目です')
      return
    }

    const timestamp = this.flood.timestamp
    if (timestamp === undefined) {
      return
    }
    const res = await Api.put(`/flood/${timestamp}`, this.flood)
    if (res !== null) {
      this.sync()
      this.dialog = false
    }
  }

  async del(row: FloodPoint): Promise<void> {
    if (row.timestamp === undefined) {
      return
    }
    if (confirm(`${row.osname}の氾濫地点を削除します。\nよろしいですか？`) !== true) {
      return
    }
    const res = await Api.delete(`/flood/${row.timestamp}`)
    if (res !== null) {
      this.sync()
    }
  }

  sort(sortEvent: SortParam): void {
    let order = []
    const reverse = sortEvent.desc
    switch (sortEvent.field) {
      case '識別番号':
        order = [
          { key: 'id', reverse: reverse },
          { key: 'river', reverse: false },
          { key: 'osid', reverse: false }
        ]
        break
      case '河川名':
        order = [
          { key: 'river', reverse: reverse },
          { key: 'id', reverse: true },
          { key: 'osid', reverse: false }
        ]
        break
      case '観測所名':
        order = [
          { key: 'osname', reverse: reverse },
          { key: 'id', reverse: true },
          { key: 'river', reverse: false }
        ]
        break
      default:
        order = [
          { key: 'id', reverse: true },
          { key: 'river', reverse: false },
          { key: 'osid', reverse: false }
        ]
        break
    }
    this.rows.sort(Common.compareHelper(order))
  }

  search(): void {
    this.rows = this.baseRows.filter((row: FloodPoint) => {
      if (this.osname !== '' && row.osname.indexOf(this.osname) === -1) {
        return false
      }
      return this.river === this.FILTER_NONE || row.river === this.river
    })
    this.sort(this.sortParam)
  }
}
