




















































































































































































import { Component, Vue } from 'vue-property-decorator'
import { Observatory, Report, Destination } from 'gnm-pref-report-shared'
import ObservatoryHeader from '@/components/ObservatoryHeader.vue'
import CcEditor from '@/components/CcEditor.vue'
import { Api } from '@/utils/api'
import { Consts } from '@/utils/consts'
import { Common } from '@/utils/common'
import app from '@/store/app'

interface ReportRow {
  osid: string
  timestamp: number
  num: number
  title: string
  sendDateTime: string
  floodLevel: number
}

@Component({
  components: {
    ObservatoryHeader,
    CcEditor
  }
})
export default class StageHistory extends Vue {
  observatory: Observatory = {
    id: '',
    name: ''
  }
  target: Report | null = null
  reports: ReportRow[] = []
  selectedDest: Destination | null = null
  showedDialog = false
  isSortFloodLevel = false

  get mode(): string {
    return this.$route.params.mode
  }

  get osid(): string {
    return this.$route.params.osid
  }

  created(): void {
    this.sync()
  }

  async sync(): Promise<void> {
    this.reports = []
    const params = {
      mode: this.mode,
      osid: this.osid
    }
    const res = await Api.get(`/stage-history/list`, params)
    if (res === null) {
      return
    }
    this.reports = res.reports
    this.observatory = res.observatory
    if (this.reports.length > 0) {
      this.selectReport(this.reports[0])
    }
  }

  async selectReport(r: ReportRow): Promise<void> {
    this.target = null
    if (r === undefined) {
      return
    }
    const params = {
      mode: this.mode,
      osid: this.osid,
      timestamp: r.timestamp
    }
    const res = await Api.get(`/stage-history/one`, params)
    if (res === null) {
      return
    }
    this.target = res
  }

  preview(s3Key: string): void {
    if (s3Key === undefined || s3Key === '') {
      return
    }
    const url = `${Consts.S3_PUBLIC_URL}/${s3Key}`
    window.open(url, '_blank')
  }

  getFloodColor(floodLevel: number): string {
    return Common.getFloodLevelColor(floodLevel)
  }

  async resend(dest: Destination): Promise<void> {
    if (this.target === null) {
      return
    }
    let address = dest.mail
    if (dest.cc !== undefined && dest.cc !== '') {
      address += '\n' + dest.cc.replace(',', '\n')
    }
    if (confirm(`メールを再送します。\n送信してもよろしいですか？\n\n${address}`) !== true) {
      return
    }
    const param = {
      mode: this.mode,
      osid: this.osid,
      timestamp: this.target.timestamp,
      address: dest.mail,
      cc: dest.cc,
      destId: dest.id
    }
    const res = await Api.post(`/stage-mail`, param)
    if (res.sended === true) {
      app.setSnackbar('送信しました')
      dest.sendedMail = true
    }
  }

  async resendFax(dest: Destination): Promise<void> {
    if (this.target === null) {
      return
    }
    const faxNum = dest.fax
    if (confirm(`FAXを再送します。\n送信してもよろしいですか？\n\n${faxNum}`) !== true) {
      return
    }
    const param = {
      mode: this.mode,
      faxNum: faxNum,
      osid: this.osid,
      timestamp: this.target.timestamp
    }
    const res = await Api.post(`/stage-fax/send`, param)
    if (res.sended === true) {
      app.setSnackbar('送信しました')
      dest.sendedFax = true
    }
  }

  async resendNotification(dest: Destination): Promise<void> {
    if (this.target === null) {
      return
    }
    if (confirm(`WEB通知を再送します。\n送信してもよろしいですか？`) !== true) {
      return
    }
    const param = {
      section: 'stage',
      mode: this.mode,
      osid: this.osid,
      timestamp: this.target.timestamp,
      destId: dest.id
    }
    const res = await Api.post(`/notification/resend`, param)
    if (res.sended === true) {
      app.setSnackbar('送信しました')
      dest.sendedNoticeTime = res.time
    }
  }

  edit(dest: Destination): void {
    this.selectedDest = { ...dest }
    this.showedDialog = true
  }

  ccChanged(cc: string): void {
    if (this.selectedDest !== null) {
      this.selectedDest.cc = cc
    }
  }

  async save(): Promise<void> {
    if (this.target === null || this.selectedDest === null) {
      return
    }
    const destId = this.selectedDest.id
    const mail = this.selectedDest.mail
    const cc = this.selectedDest.cc === undefined ? '' : this.selectedDest.cc
    const fax = this.selectedDest.fax
    const remarks = this.selectedDest.remarks

    if (mail === '' && cc !== '') {
      alert('メールアドレスを入力して下さい(CCのみは不可)')
      return
    }

    const params = {
      mode: this.mode,
      osid: this.target.osid,
      timestamp: this.target.timestamp,
      destId: destId,
      mail: mail,
      cc: cc,
      fax: fax,
      remarks: remarks
    }
    const res = await Api.put('/stage-history/destination', params)
    if (res !== null) {
      const dest = this.target.destinations[destId]
      dest.mail = mail
      dest.fax = fax
      dest.cc = cc
      dest.remarks = remarks
      this.cancel()
    }
  }

  async received(section: 'mail' | 'fax', dest: Destination): Promise<void> {
    if (this.target === null || dest === undefined || dest === null) {
      return
    }
    const destId = dest.id
    let received = section === 'mail' ? dest.receivedMail : dest.receivedFax
    if (received === undefined || typeof received !== 'boolean') {
      received = true
    } else {
      received = !received
    }

    const params = {
      mode: this.mode,
      osid: this.target.osid,
      timestamp: this.target.timestamp,
      destId: destId,
      section: section,
      received: received
    }
    const res = await Api.put('/stage-history/received', params)
    if (res !== null) {
      if (section === 'mail') {
        dest.receivedMail = received
        dest.receivedMailTime = res.time
      } else {
        dest.receivedFax = received
        dest.receivedFaxTime = res.time
      }
    }
  }

  cancel(): void {
    this.selectedDest = null
    this.showedDialog = false
  }

  isResendNotification(dest: Destination): boolean {
    return (
      dest.suigaiSysId !== undefined &&
      dest.suigaiSysId !== '' &&
      (dest.sendedNoticeTime === '' || dest.sendedNoticeTime === undefined)
    )
  }

  sortFloodLevel(): void {
    this.isSortFloodLevel = !this.isSortFloodLevel
    if (this.isSortFloodLevel === true) {
      this.reports.sort((a, b) => {
        // floodLevel
        if (a.floodLevel > b.floodLevel) return -1
        if (a.floodLevel < b.floodLevel) return 1
        // 出動 > 準備 > 待機
        if (a.title.includes('出動') === true) return -1
        if (a.title.includes('出動') === false) return 1
        if (a.title.includes('準備') === true) return -1
        if (a.title.includes('準備') === false) return 1
        if (a.title.includes('待機') === true) return -1
        if (a.title.includes('待機') === false) return 1
        // timestamp
        if (a.timestamp > b.timestamp) return -1
        if (a.timestamp < b.timestamp) return 1
        return 0
      })
    } else {
      this.reports.sort((a, b) => {
        if (a.timestamp > b.timestamp) return -1
        if (a.timestamp < b.timestamp) return 1
        return 0
      })
    }
  }

  getDestinationClass(dest: Destination): string {
    if (
      (dest.receivedFaxTime !== undefined && dest.receivedFaxTime !== '') ||
      (dest.receivedMailTime !== undefined && dest.receivedMailTime !== '') ||
      (dest.receivedNoticeTime !== undefined && dest.receivedNoticeTime !== '')
    ) {
      return 'received'
    } else {
      return 'not-received'
    }
  }
}
