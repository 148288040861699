import { StageLevel } from '@/types'
import app from '@/store/app'
import router from '@/router'

export class Common {
  static get isProduction(): boolean {
    return process.env.NODE_ENV === 'production'
  }

  static get isDevelopment(): boolean {
    return this.isProduction !== true
  }

  static getStageLevelColor(stageLevel: StageLevel | ''): string {
    switch (stageLevel) {
      case 'standby':
        return '#008000'
      case 'warning':
        return '#ffa500'
      case 'evacuation':
        return '#ff2800'
      case 'dangerous':
        return '#ad02ad'
      case 'outbreak':
        return '#3b3b3b'
      default:
        return '#eeeeee'
    }
  }

  static getFloodLevelColor(floodLevel: number): string {
    switch (floodLevel) {
      case 1:
        return this.getStageLevelColor('standby')
      case 2:
        return this.getStageLevelColor('warning')
      case 3:
        return this.getStageLevelColor('evacuation')
      case 4:
        return this.getStageLevelColor('dangerous')
      case 5:
        return this.getStageLevelColor('outbreak')
      default:
        return this.getStageLevelColor('')
    }
  }

  static compareHelper(order: { key: string; reverse: boolean }[]) {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    return (a: any, b: any) => {
      for (let i = 0; i < order.length; i++) {
        const order_by = order[i].reverse ? 1 : -1
        if (a[order[i].key] < b[order[i].key]) return order_by
        if (a[order[i].key] > b[order[i].key]) return order_by * -1
      }
      return 0
    }
  }

  static showMap(lat: number, lng: number): void {
    const latLng = `${lat},${lng}`
    const resolvedRoute = router.resolve({
      name: 'Map',
      params: {
        latLng: latLng
      }
    })
    const url = resolvedRoute.href
    if (app.mapTab === null || app.mapTab.closed === true) {
      const tab = window.open(url, '_blank')
      if (tab !== null) {
        app.setMapTab(tab)
      }
    } else {
      app.mapTab.location.href = url
      app.mapTab.location.reload()
      setTimeout(() => {
        app.mapTab?.focus()
      }, 500)
    }
  }

  static validEmail(val: string): boolean {
    const pattern = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/
    return pattern.test(val)
  }
}
