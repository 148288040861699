"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DamCategory = exports.StageCategory = void 0;
var StageCategory;
(function (StageCategory) {
    // 水防警報
    StageCategory[StageCategory["STANDBY"] = 10] = "STANDBY";
    // 水位到達
    StageCategory[StageCategory["DANGER"] = 20] = "DANGER";
    // 氾濫発生
    StageCategory[StageCategory["FLOOD"] = 30] = "FLOOD";
})(StageCategory = exports.StageCategory || (exports.StageCategory = {}));
var DamCategory;
(function (DamCategory) {
    // 様式1 洪水警戒体制発令の通知 (発令時点)
    DamCategory[DamCategory["STYLE1"] = 1] = "STYLE1";
    // 様式2 洪水警戒体制解除の情報 (解除時点)
    DamCategory[DamCategory["STYLE2"] = 2] = "STYLE2";
    // 様式3 越流開始の通知
    DamCategory[DamCategory["STYLE3"] = 3] = "STYLE3";
    // 様式4 放流量増加による急激な水位上昇の通知
    DamCategory[DamCategory["STYLE4"] = 4] = "STYLE4";
    // 様式5 洪水量に達した旨の速報
    DamCategory[DamCategory["STYLE5"] = 5] = "STYLE5";
    // 様式6 洪水量以下に低下
    DamCategory[DamCategory["STYLE6"] = 6] = "STYLE6";
    // 様式7 クレストからの越流予告の通知 (○時間前)
    DamCategory[DamCategory["STYLE7"] = 7] = "STYLE7";
    // 様式8 クレストからの越流予告の通知 (1時間前)
    DamCategory[DamCategory["STYLE8"] = 8] = "STYLE8";
    // 様式9 クレストからの越流開始の速報
    DamCategory[DamCategory["STYLE9"] = 9] = "STYLE9";
    // 様式10 クレストからの越流終了の速報
    DamCategory[DamCategory["STYLE10"] = 10] = "STYLE10";
    // 事前1 事前放流体制の通知
    DamCategory[DamCategory["STYLE21"] = 21] = "STYLE21";
    // 事前2 事前放流体制解除の情報
    DamCategory[DamCategory["STYLE22"] = 22] = "STYLE22";
    // 事前3 事前放流1時間前の通知
    DamCategory[DamCategory["STYLE23"] = 23] = "STYLE23";
    // 事前4 事前放流 開始の通知
    DamCategory[DamCategory["STYLE24"] = 24] = "STYLE24";
    // 事前5 事前放流 中止の通知
    DamCategory[DamCategory["STYLE25"] = 25] = "STYLE25";
    // 事前6 洪水警戒体制の通知 兼 事前放流体制解除の情報
    DamCategory[DamCategory["STYLE26"] = 26] = "STYLE26";
})(DamCategory = exports.DamCategory || (exports.DamCategory = {}));
