























import { Component, Vue } from 'vue-property-decorator'
import { Consts } from '@/utils/consts'
import auth from '@/store/auth'
import app from '@/store/app'

@Component
export default class Login extends Vue {
  title = Consts.APP_TITLE
  userId = ''
  password = ''

  created(): void {
    auth.logout()
  }

  async login(): Promise<void> {
    const signed = await auth.login({
      userId: this.userId,
      password: this.password
    })
    if (signed === true) {
      await auth.getAccountInfo()
      if (auth.isStage === true) {
        this.$router.push('/stage/execution')
      } else {
        this.$router.push('/dam/execution')
      }
    } else {
      app.setSnackbar('ログインに失敗しました')
    }
  }
}
