

























































































































































































import { MlitSection, MlitObservatory } from '@/types'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Destination } from 'gnm-pref-report-shared'
import mlit from '@/store/mlit'
import { Common, Consts, Api } from '@/utils'
import app from '@/store/app'
import CcEditor from '@/components/CcEditor.vue'

interface MlitReport {
  num: string
  osid: string
  timestamp: number
  subject: string
  branch: string
  forwardDatetime: string
  attachmentS3Key: string
  destinations: { [key: string]: Destination }
}

@Component({
  components: {
    CcEditor
  }
})
export default class MlitHistory extends Vue {
  target: MlitReport | null = null
  reports: MlitReport[] = []
  selectedDest: Destination | null = null
  showedDialog = false

  offices: string[] = []
  osnames: string[] = []
  searchItem = {
    office: '',
    osname: ''
  }
  observatories: MlitObservatory[] = []

  get section(): MlitSection {
    return this.$route.params.section === 'dam' ? 'dam' : 'stage'
  }

  get osid(): string {
    return this.$route.params.osid
  }

  async created(): Promise<void> {
    await this.initSection()
    await this.sync()
  }

  async initSection(): Promise<void> {
    this.observatories = await mlit.fetch(this.section)
    this.offices = ['']
    this.osnames = ['']
    this.observatories.forEach((os: MlitObservatory) => {
      if (this.offices.includes(os.office) === false) {
        this.offices.push(os.office)
      }
      this.osnames.push(os.name)
    })
    this.searchItem = {
      office: '',
      osname: ''
    }
    if (this.osid !== '') {
      const finded = this.observatories.find((obs: MlitObservatory) => obs.id === this.osid)
      if (finded !== undefined) {
        this.searchItem.osname = finded.name
        this.searchItem.office = finded.office
      }
    }
  }

  async sync(): Promise<void> {
    const params = {
      office: this.searchItem.office,
      osname: this.searchItem.osname,
      forwarded: 1
    }
    this.reports = await Api.get(`/mlit/list/${this.section}`, params)
    this.reports.forEach((row: MlitReport) => {
      row.num = row.branch.replace('第', '').replace('号', '')
      row.subject = row.subject.replace('【要受信確認】', '')
      Object.values(row.destinations).forEach((d: Destination) => {
        if (d.cc === undefined) {
          d.cc = ''
        }
      })
    })
    if (this.reports.length > 0) {
      this.selectReport(this.reports[0])
    } else {
      this.selectReport(null)
    }
  }

  changeOffice(): void {
    this.searchItem.osname = ''
    this.sync()
  }

  changeOsname(): void {
    const finded = this.observatories.find((obs: MlitObservatory) => obs.name === this.searchItem.osname)
    if (finded !== undefined) {
      this.searchItem.office = finded.office
    }
    this.sync()
  }

  async selectReport(row: MlitReport | null): Promise<void> {
    this.target = row
  }

  preview(): void {
    if (this.target === null) {
      return
    }
    const key = this.target.attachmentS3Key
    if (key === undefined || key === '') {
      return
    }
    const url = `${Consts.S3_PUBLIC_URL}/${key}`
    window.open(url, '_blank')
  }

  isResendNotification(dest: Destination): boolean {
    return (
      dest.suigaiSysId !== undefined &&
      dest.suigaiSysId !== '' &&
      (dest.sendedNoticeTime === '' || dest.sendedNoticeTime === undefined)
    )
  }

  async resendMail(dest: Destination): Promise<void> {
    if (this.target === null) {
      return
    }
    let address = dest.mail
    if (dest.cc !== undefined && dest.cc !== '') {
      address += '\n' + dest.cc.replace(',', '\n')
    }
    if (confirm(`メールを再送します。\n送信してもよろしいですか？\n\n${address}`) !== true) {
      return
    }
    const param = {
      section: this.section,
      timestamp: this.target.timestamp,
      address: dest.mail,
      cc: dest.cc,
      destId: dest.id
    }
    const res = await Api.post(`/mlit/mail/resend`, param)
    if (res.sended === true) {
      app.setSnackbar('送信しました')
      dest.sendedMail = true
      dest.sendedMailTime = res.time
    }
  }

  async resendFax(dest: Destination): Promise<void> {
    if (this.target === null) {
      return
    }
    const faxNum = dest.fax
    if (confirm(`FAXを再送します。\n送信してもよろしいですか？\n\n${faxNum}`) !== true) {
      return
    }
    const param = {
      section: this.section,
      timestamp: this.target.timestamp,
      faxNum: faxNum,
      destId: dest.id
    }
    const res = await Api.post(`/mlit/fax/resend`, param)
    if (res.sended === true) {
      app.setSnackbar('送信しました')
      dest.sendedFax = true
      dest.sendedFaxTime = res.time
    }
  }

  async resendNotification(dest: Destination): Promise<void> {
    if (this.target === null) {
      return
    }
    if (confirm(`WEB通知を再送します。\n送信してもよろしいですか？`) !== true) {
      return
    }
    const param = {
      section: this.section,
      timestamp: this.target.timestamp,
      destId: dest.id
    }
    const res = await Api.post(`/mlit/notification/resend`, param)
    if (res.sended === true) {
      app.setSnackbar('送信しました')
      dest.sendedNoticeTime = res.time
    }
  }

  edit(dest: Destination): void {
    this.selectedDest = { ...dest }
    this.showedDialog = true
  }

  ccChanged(cc: string): void {
    if (this.selectedDest !== null) {
      this.selectedDest.cc = cc
    }
  }

  async save(): Promise<void> {
    if (this.target === null || this.selectedDest === null) {
      return
    }
    const destId = this.selectedDest.id
    const mail = this.selectedDest.mail
    const cc = this.selectedDest.cc === undefined ? '' : this.selectedDest.cc
    const fax = this.selectedDest.fax
    const remarks = this.selectedDest.remarks

    if (mail === '' && cc !== '') {
      alert('メールアドレスを入力して下さい(CCのみは不可)')
      return
    }

    const params = {
      section: this.section,
      timestamp: this.target.timestamp,
      destId: destId,
      mail: mail,
      cc: cc,
      fax: fax,
      remarks: remarks
    }
    const res = await Api.put('/mlit/destination', params)
    if (res !== null) {
      const dest = this.target.destinations[destId]
      dest.mail = mail
      dest.cc = cc
      dest.fax = fax
      dest.remarks = remarks
      this.$nextTick(() => {
        const selected = this.target
        this.selectReport(null)
        this.selectReport(selected)
      })
      this.cancel()
    }
  }

  async received(category: 'mail' | 'fax', dest: Destination): Promise<void> {
    if (this.target === null || dest === undefined || dest === null) {
      return
    }
    const destId = dest.id
    let received = category === 'mail' ? dest.receivedMail : dest.receivedFax
    if (received === undefined || typeof received !== 'boolean') {
      received = true
    } else {
      received = !received
    }

    const params = {
      section: this.section,
      timestamp: this.target.timestamp,
      destId: destId,
      category: category,
      received: received
    }
    const res = await Api.put('/mlit/received', params)
    if (res !== null) {
      if (category === 'mail') {
        dest.receivedMail = received
        dest.receivedMailTime = res.time
      } else {
        dest.receivedFax = received
        dest.receivedFaxTime = res.time
      }
    }
  }

  cancel(): void {
    this.selectedDest = null
    this.showedDialog = false
  }
}
