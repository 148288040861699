




import { Component, Vue, Prop } from 'vue-property-decorator'
import { StageLevel } from '@/types'
import { Common } from '@/utils/common'

@Component
export default class StageChip extends Vue {
  @Prop()
  level!: StageLevel

  levels = {
    standby: {
      name: '',
      color: '#008000'
    }
  }

  get color(): string {
    return Common.getStageLevelColor(this.level)
  }
}
