























































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Api } from '@/utils/api'
import { Consts } from '@/utils/consts'
import moment from 'moment'
import { Datetime as VueDatetime } from 'vue-datetime'

interface Row {
  id: string
  level?: number
  qInAll?: number
  qOutAll?: number
  floodFlow?: number
  floodFlow50?: number
  limitLevel?: number
  fullLevel?: number
  surchargeLevel?: number
  status: string
  checked: boolean
  history: string
  hold?: boolean
  latestTimestamp?: number
  limitCapacity?: number
  capacity?: number
}

@Component({
  components: {
    VueDatetime
  }
})
export default class Dam extends Vue {
  rows: Row[] = []
  headerHeight = 0
  footerHeight = 60
  tableHeight: number | 'auto' = 'auto'

  isBulkIndeterminate = false
  isBulkCheck = false

  showedExportDialog = false
  exportDate1 = moment().subtract(1, 'month').format('YYYY-MM-DDTHH:00')
  exportDate2 = moment().add(1, 'hours').format('YYYY-MM-DDTHH:00')

  colors = {
    floodFlow50: '#42a5f5',
    floodFlow: '#ffa500',
    limitLevel: '#008000',
    fullLevel: '#ff2800',
    surchargeLevel: '#000000'
  }

  get mode(): string {
    return this.$route.params.mode
  }

  get isPractice(): boolean {
    return this.mode === Consts.MODE.practice
  }

  @Watch('mode')
  changeMode(): void {
    this.sync()
  }

  created(): void {
    this.sync()
    window.addEventListener('resize', this.resize)
  }

  destroyed(): void {
    window.removeEventListener('resize', this.resize)
  }

  mounted(): void {
    const damHeader = this.$refs.dam_header as Element
    if (damHeader === undefined) {
      this.headerHeight = 0
    } else {
      this.headerHeight = damHeader.clientHeight
    }
    this.resize()
  }

  resize(): void {
    if (this.headerHeight <= 0) {
      this.tableHeight = 'auto'
    } else {
      this.tableHeight = window.innerHeight - Consts.APP_BAR_HEIGHT - this.headerHeight - this.footerHeight
    }
  }

  async sync(): Promise<void> {
    this.isBulkCheck = false
    this.isBulkIndeterminate = false
    this.rows = []
    const response: Row[] = await Api.get(`/dam/list`, {
      mode: this.mode
    })
    for (const row of response) {
      row.checked = false
      row.status = row.hold === undefined || row.hold !== true ? '新規' : '保留'
      row.history = row.history === '' || row.history === undefined ? '履歴' : row.history
      this.rows.push(row)
    }
  }

  getTransitionIcon(diff: number): string {
    if (diff === undefined) {
      return ''
    } else if (diff > 0) {
      return 'mdi-arrow-top-right'
    } else if (diff < 0) {
      return 'mdi-arrow-bottom-right'
    } else if (diff === 0) {
      return 'mdi-arrow-right'
    } else {
      return ''
    }
  }

  getCapacityPer(row: Row): string {
    if (row.capacity === undefined || row.limitCapacity === undefined) {
      return '-'
    } else {
      const per = Math.round((row.capacity / row.limitCapacity) * 100)
      return `${per} %`
    }
  }

  async deleteHistory(): Promise<void> {
    const checked = this.rows.filter((row: Row) => row.checked === true && row.latestTimestamp !== undefined)
    if (checked.length === 0) {
      this.rows.forEach((row: Row) => {
        row.checked = false
        this.isBulkCheck = false
        this.isBulkIndeterminate = false
      })
      return
    }
    if (confirm('発表状況を消去します。\n処理を実行してもよろしいですか？\n(履歴には残ります)') !== true) {
      return
    }
    await Promise.all(
      checked.map(async (row: Row) => {
        const params = {
          mode: this.mode,
          osid: row.id
        }
        await Api.delete(`/dam-history/latest`, params)
      })
    )

    await this.sync()
  }

  async deleteHold(): Promise<void> {
    const checked = this.rows.filter((row: Row) => row.checked === true && row.hold !== undefined && row.hold === true)
    if (checked.length === 0) {
      this.rows.forEach((row: Row) => {
        row.checked = false
        this.isBulkCheck = false
        this.isBulkIndeterminate = false
      })
      return
    }
    if (confirm('作成中の文章を破棄します。\n処理を実行してもよろしいですか？') !== true) {
      return
    }
    await Promise.all(
      checked.map(async (row: Row) => {
        const params = {
          mode: this.mode,
          osid: row.id
        }
        await Api.delete(`/dam-report/hold`, params)
      })
    )

    await this.sync()
  }

  checkBulk(): void {
    this.isBulkIndeterminate = false
    this.rows.forEach((row: Row) => {
      row.checked = this.isBulkCheck
    })
  }

  checkRow(): void {
    const checked = this.rows.filter((row: Row) => {
      return row.checked === true
    })
    if (checked.length === 0) {
      this.isBulkCheck = false
      this.isBulkIndeterminate = false
    } else if (this.rows.length === checked.length) {
      this.isBulkCheck = true
      this.isBulkIndeterminate = false
    } else {
      this.isBulkIndeterminate = true
    }
  }

  getLevelStyle(
    row: Row,
    field: 'floodFlow50' | 'floodFlow' | 'limitLevel' | 'fullLevel' | 'surchargeLevel'
  ): { [key: string]: string } {
    const targetLevel = row[field]
    const currentLevel = field === 'floodFlow' || field === 'floodFlow50' ? row.qInAll : row.level
    if (targetLevel === undefined || currentLevel === undefined) {
      return {}
    }
    if (currentLevel < targetLevel) {
      return {}
    } else {
      return {
        color: this.colors[field],
        fontWeight: 'bold'
      }
    }
  }

  async exportHistory(): Promise<void> {
    const convertUtc = (date: string) => {
      const jst = moment(date)
      return jst.subtract(9, 'hours').format('YYYY/MM/DD HH:mm')
    }
    let date1 = convertUtc(this.exportDate1)
    let date2 = convertUtc(this.exportDate2)
    if (date1 > date2) {
      const tmp = date1
      date1 = date2
      date2 = tmp
    }
    const res = await Api.get('/excel/dam-history', {
      mode: this.mode,
      date1: date1,
      date2: date2
    })
    if (res.s3 !== undefined) {
      const url = `${Consts.S3_PUBLIC_URL}/${res.s3}`
      window.open(url, '_blank')
    }
  }
}
