import Vue from 'vue'
import axios, { AxiosRequestConfig } from 'axios'
import { Consts } from '@/utils/consts'
import { LatLng } from '@/types'
import app from '@/store/app'

type ApiResponse = { [key: string]: any }

const apiBaseUrl =
  process.env.NODE_ENV === 'production' ? Consts.API_BASE_URL.production : Consts.API_BASE_URL.development

export class Api {
  static getRequestConfig(): AxiosRequestConfig {
    const token = Vue.$cookies.get(Consts.SESSION_KEY_TOKEN)
    return {
      headers: {
        'x-api-key': Consts.X_API_KEY,
        'x-auth-token': token
      },
      responseType: 'json'
    }
  }

  static async get(path: string, params: { [key: string]: any } = {}): Promise<any> {
    app.setLoading(true)
    return axios
      .create(this.getRequestConfig())
      .get(`${apiBaseUrl}${path}`, { params: params })
      .then((response: ApiResponse) => {
        app.setLoading(false)
        return response.data
      })
      .catch((err: ApiResponse) => {
        app.setLoading(false)
        console.log(err)
        return null
      })
  }

  static async put(path: string, params: { [key: string]: any } = {}): Promise<any> {
    app.setLoading(true)
    return axios
      .create(this.getRequestConfig())
      .put(`${apiBaseUrl}${path}`, params)
      .then((response: ApiResponse) => {
        app.setLoading(false)
        return response.data
      })
      .catch((err: ApiResponse) => {
        app.setLoading(false)
        console.log(err)
        return null
      })
  }

  static async post(path: string, params: { [key: string]: any } = {}): Promise<any> {
    app.setLoading(true)
    return axios
      .create(this.getRequestConfig())
      .post(`${apiBaseUrl}${path}`, params)
      .then((response: ApiResponse) => {
        app.setLoading(false)
        return response.data
      })
      .catch((err: ApiResponse) => {
        app.setLoading(false)
        console.log(err)
        return null
      })
  }

  static async delete(path: string, params: { [key: string]: any } = {}): Promise<any> {
    app.setLoading(true)
    return axios
      .create(this.getRequestConfig())
      .delete(`${apiBaseUrl}${path}`, { data: params })
      .then((response: ApiResponse) => {
        app.setLoading(false)
        return response.data
      })
      .catch((err: ApiResponse) => {
        app.setLoading(false)
        console.log(err)
        return null
      })
  }
}

export class GoogleMapApi {
  static readonly baseUrl = 'https://maps.googleapis.com/maps/api/geocode/json'

  static async reverseGeocoding(position: LatLng): Promise<any> {
    const path = `${this.baseUrl}?latlng=${position.lat},${position.lng}&sensor=false&key=${Consts.GOOGLE_MAP_KEY}`
    return axios
      .create({
        responseType: 'json'
      })
      .get(path)
      .then((response: ApiResponse) => {
        let res = ''
        if (response.data.status === 'OK' && response.data.results.length > 0) {
          res = response.data.results[0].formatted_address
          const arr = res.split(' ')
          if (arr.length >= 2) {
            res = arr[1]
          }
        }
        return res
      })
      .catch((err: ApiResponse) => {
        console.log(err)
        return null
      })
  }
}
