
























































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import moment from 'moment'
import app from '@/store/app'
import { Report, ReportContent, SharedUtils, StageCategory, FloodPoint } from 'gnm-pref-report-shared'
import { Datetime } from 'vue-datetime'
import ObservatoryHeader from '@/components/ObservatoryHeader.vue'
import { Consts, Common, Api } from '@/utils'

@Component({
  components: {
    ObservatoryHeader,
    vDatetime: Datetime
  }
})
export default class Stage extends Vue {
  report: Report = {
    osid: '',
    category: StageCategory.STANDBY,
    floodLevel: 0,
    num: 0,
    timestamp: 0,
    sendDateTime: '',
    sendDateTimeUtc: '',
    title: '',
    contents: [],
    observatory: {
      id: '',
      name: ''
    },
    office: {
      id: '',
      name: '',
      station: '',
      tel: ''
    },
    destinations: {}
  }
  freeDialog = false
  titleItems: string[] = []
  categoryId: StageCategory = this.categories[0].id
  sendDateTime = moment().format('YYYY-MM-DDTHH:mm')
  freeInputContent: ReportContent | null = null
  freeInputArticle = ''
  telemeter?: { level: number; dateTime: number; transition: string }
  flood: FloodPoint = {
    id: '',
    river: '',
    osid: '',
    osname: '',
    address: '',
    lr: '',
    distMark: '',
    lat: '',
    lng: '',
    place: '',
    breakWidth: '',
    summary: ''
  }
  floodTab: 'input' | 'list' = 'input'
  floodPoints: FloodPoint[] = []
  isYahoo = false
  isReportNumReset = false
  isReportNumResetVisible = true
  isHold = false

  get categories(): { id: number; name: string }[] {
    if (['2561_67', '2561_52'].includes(this.osid)) {
      return [{ id: StageCategory.STANDBY, name: '水防警報' }]
    } else {
      return [
        { id: StageCategory.STANDBY, name: '水防警報' },
        { id: StageCategory.DANGER, name: '水位到達' },
        { id: StageCategory.FLOOD, name: '氾濫発生' }
      ]
    }
  }

  get mode(): string {
    return this.$route.params.mode
  }

  get isPractice(): boolean {
    return this.mode === Consts.MODE.practice
  }

  async created(): Promise<void> {
    await this.sync()
  }

  mounted(): void {
    // マップから地点読み込み
    const w: any = window
    w.loadMapPoints = (address: string, lat: string, lng: string) => {
      this.flood.address = address
      this.flood.lat = lat
      this.flood.lng = lng
      this.refreshFloodPlace()
    }
  }

  async changeFloodTab(tab: 'input' | 'list'): Promise<void> {
    this.floodTab = tab
    if (tab === 'list' && this.floodPoints.length === 0) {
      const res = await Api.get(`/flood/${this.report.osid}`)
      this.floodPoints = res
    }
  }

  refreshFloodPlace(): void {
    if (this.flood.address === '') {
      return
    }
    this.flood.place = this.flood.address
    if (this.flood.place !== '' && this.flood.lr !== '' && this.flood.lr !== '不明') {
      this.flood.place += `（${this.flood.lr}）`
    }
    this.initReportContents()
  }

  floodListSelect(row: FloodPoint): void {
    this.flood.place = row.place
    this.initReportContents()
  }

  async sync(): Promise<void> {
    const res = await Api.get(`/stage/one`, {
      mode: this.mode,
      osid: this.osid
    })
    this.report = {
      osid: this.osid,
      timestamp: Date.now(),
      num: res.num,
      sendDateTime: SharedUtils.getDateTimeJp(),
      sendDateTimeUtc: moment().utc().format('YYYY/MM/DD HH:mm'),
      category: StageCategory.STANDBY,
      floodLevel: 1,
      observatory: res.observatory,
      office: res.office,
      title: '水防警報(待機)',
      contents: [],
      destinations: res.destinations
    }
    this.isReportNumReset = res.num === 1
    this.isReportNumResetVisible = res.num > 1

    this.flood.river = this.report.observatory.river === undefined ? '' : this.report.observatory.river
    this.flood.osid = this.report.observatory.id === undefined ? '' : this.report.observatory.id
    this.flood.osname = this.report.observatory.name === undefined ? '' : this.report.observatory.name

    this.telemeter = res.telemeter
    this.changeCategory()

    // 保留データのロード
    const hold = await Api.get(`/stage-report/hold`, {
      mode: this.mode,
      osid: this.osid
    })
    if (hold === null || hold.report === null) {
      this.isHold = false
      // タイトルを次の工程へ移動
      if (res.latestTitle !== undefined && res.latestTitle !== '') {
        this.setNextTitle(res.latestTitle)
      }
    } else {
      if (hold.report.hash !== undefined) {
        delete hold.report.hash
      }
      this.isHold = true
      this.report = hold.report
      this.categoryId = this.report.category === undefined ? this.categoryId : (this.report.category as StageCategory)
      this.refreshTitleItems()
      app.setSnackbar('保留データを読み込みました')
    }
  }

  get osid(): string {
    return this.$route.params.osid
  }

  isStandBy(): boolean {
    return this.categoryId === StageCategory.STANDBY
  }

  isDanger(): boolean {
    return this.categoryId === StageCategory.DANGER
  }

  isFlood(): boolean {
    return this.categoryId === StageCategory.FLOOD
  }

  changeCategory(): void {
    this.refreshTitleItems()
    this.report.title = this.titleItems[0]
    this.initReportContents()
  }

  setNextTitle(latestTitle: string): void {
    if (this.telemeter === undefined) {
      return
    }
    if (latestTitle === '水防警報（解除）') {
      return
    }
    const level = this.telemeter.level

    if (this.report.observatory.standbyLevel !== undefined) {
      // 基準水位を下回った場合
      if (level < this.report.observatory.standbyLevel) {
        if (latestTitle !== '') {
          this.report.title = this.titleItems[3]
        }
        this.initReportContents()
        return
      }
    }

    // 氾濫発生
    if (latestTitle.includes('氾濫発生')) {
      this.categoryId = StageCategory.FLOOD
      this.refreshTitleItems()
      this.report.title = this.titleItems[0]
      this.initReportContents()
      return
    }

    // 氾濫危険
    if (this.report.observatory.dangerousLevel !== undefined) {
      if (level >= this.report.observatory.dangerousLevel || latestTitle.includes('氾濫危険')) {
        this.categoryId = StageCategory.DANGER
        this.refreshTitleItems()
        this.report.title = this.titleItems[2]
        this.initReportContents()
        return
      }
    }

    // 氾濫警戒
    if (this.report.observatory.evacuationLevel !== undefined) {
      if (level >= this.report.observatory.evacuationLevel || latestTitle.includes('氾濫警戒')) {
        this.categoryId = StageCategory.DANGER
        this.refreshTitleItems()
        this.report.title = this.titleItems[1]
        this.initReportContents()
        return
      }
    }

    // 氾濫注意
    if (this.report.observatory.warningLevel !== undefined) {
      if (level >= this.report.observatory.warningLevel || latestTitle.includes('氾濫注意')) {
        this.categoryId = StageCategory.DANGER
        this.refreshTitleItems()
        this.report.title = this.titleItems[0]
        this.initReportContents()
        return
      }
    }

    // 水防警報
    if (latestTitle.includes('出動') || latestTitle.includes('準備')) {
      this.report.title = this.titleItems[2]
      this.initReportContents()
    } else if (latestTitle.includes('待機')) {
      this.report.title = this.titleItems[1]
      this.initReportContents()
    }
  }

  refreshTitleItems(): void {
    this.titleItems = []
    if (this.categoryId === StageCategory.STANDBY) {
      this.titleItems.push('水防警報（待機）')
      this.titleItems.push('水防警報（準備）')
      this.titleItems.push('水防警報（出動）')
      this.titleItems.push('水防警報（解除）')
    } else if (this.categoryId === StageCategory.DANGER) {
      this.titleItems.push(`${this.report.observatory.river}氾濫注意情報`)
      this.titleItems.push(`${this.report.observatory.river}氾濫警戒情報`)
      this.titleItems.push(`${this.report.observatory.river}氾濫危険情報`)
    } else {
      this.titleItems.push(`${this.report.observatory.river}氾濫発生情報`)
    }
  }

  initReportContents(): void {
    this.report.contents = []
    if (this.telemeter === undefined) {
      this.report.contents.push({
        title: '(現況)',
        article: 'この観測所は欠測しています'
      })
      return
    }

    const jst = moment(this.sendDateTime)
    const d = jst.format('D日')
    const hhmm = jst.format('H時 m分')
    const fullname = `${this.report.observatory.river}の${this.report.observatory.name}水位観測所(${this.report.observatory.city})`

    const content: ReportContent = {
      title: '',
      article: ''
    }
    if (this.categoryId === StageCategory.STANDBY) {
      content.title = '(現況)'
      if (this.report.title.includes('解除')) {
        content.article = `${fullname}の水位は、${d} ${hhmm} 現在 水防団待機水位を下回りました。`
      } else {
        content.article = `${fullname}の水位は、${d} ${hhmm} 現在 ${this.telemeter.level}mです。`
        if (this.report.title.includes('待機')) {
          let word = '変化ありません'
          if (this.telemeter.transition === 'up') {
            word = '上昇しています'
          } else if (this.telemeter.transition === 'down') {
            word = '下降しています'
          }
          content.article += `\n\n${fullname}の水位は、水防団待機水位を超え、${word}。`
        }
      }
    } else if (this.categoryId === StageCategory.DANGER) {
      content.title = '(主文)'
      content.article = ''
      if (this.getFloodLevel() == 2) {
        content.article += '【　警戒レベル２相当情報　[洪水]　】\n'
        const level = this.report.observatory.warningLevel ? this.report.observatory.warningLevel : 'xxx.xx'
        content.article += `${fullname}では、${d} ${hhmm} に\n氾濫注意水位（${level}m）に達しました。`
      } else if (this.getFloodLevel() == 3) {
        content.article += '【 警戒レベル３相当情報　[洪水] 】\n'
        const level = this.report.observatory.evacuationLevel ? this.report.observatory.evacuationLevel : 'xxx.xx'
        content.article += `${fullname}では、${d} ${hhmm} に\n避難判断水位（${level}m）に達しました。`
      } else if (this.getFloodLevel() == 4) {
        content.article += '【 警戒レベル４相当情報　[洪水] 】\n'
        const level = this.report.observatory.dangerousLevel ? this.report.observatory.dangerousLevel : 'xxx.xx'
        content.article += `${fullname}では、${d} ${hhmm} に\n氾濫危険水位（${level}m）に達しました。`
      }
      content.article += `\n\n市町村からの避難情報に十分注意するとともに、適切な防災行動をとって下さい。`
    } else if (this.categoryId === StageCategory.FLOOD) {
      const place = this.flood.place === '' ? '〇〇〇' : this.flood.place
      content.title = '(主文)'
      content.article = '【 警戒レベル５相当情報　[洪水] 】\n'
      content.article += `${this.report.observatory.river}では、${place}付近より氾濫しました。`
    }
    this.report.contents.push(content)

    if (this.categoryId === StageCategory.STANDBY) {
      const content: ReportContent = {
        title: '(発表)',
        article: ''
      }
      if (this.report.title.includes('待機')) {
        content.article = '水防機関は待機してください。'
      } else if (this.report.title.includes('準備')) {
        content.article = '水防機関は準備してください。'
      } else if (this.report.title.includes('出動')) {
        content.article = '水防機関は出動してください。'
      } else if (this.report.title.includes('解除')) {
        content.article = '水防警報を解除します。'
      }
      this.report.contents.push(content)
    }
  }

  showMap(): void {
    if (this.report.observatory === undefined) {
      return
    }
    if (this.report.observatory.lat === undefined || this.report.observatory.lng === undefined) {
      return
    }
    Common.showMap(this.report.observatory.lat, this.report.observatory.lng)
  }

  showSelector(): void {
    //
  }

  async pdf(): Promise<void> {
    const jst = moment(this.sendDateTime)
    this.report.sendDateTime = SharedUtils.getDateTimeJp(jst.toDate())
    this.report.sendDateTimeUtc = jst.utc().format('YYYY/MM/DD HH:mm')
    this.report.category = this.categoryId
    this.report.floodLevel = this.getFloodLevel()
    const param = {
      mode: this.mode,
      report: this.report
    }
    param.report.num = this.isReportNumReset === true ? 1 : this.report.num
    const res = await Api.post('/stage-pdf/spot', param)
    if (res === undefined || res === null || res.url === undefined) {
      return
    }
    window.open(res.url, '_blank')
  }

  showFreeDialog(content: ReportContent): void {
    if (content === null) {
      return
    }
    this.freeInputContent = content
    this.freeInputArticle = content.article
    this.freeDialog = true
  }

  saveFreeDialog(): void {
    if (this.freeInputContent === null || this.freeInputContent.article === undefined) {
      return
    }
    this.freeInputContent.article = this.freeInputArticle
    this.freeDialog = false
  }

  async saveFlood(): Promise<void> {
    // 地点登録
    if (this.isFlood() !== true || this.flood.place === '') {
      return
    }
    if (this.flood.timestamp === undefined) {
      const res = await Api.post('/flood', this.flood)
      if (res !== null && res.timestamp !== undefined && res.id !== undefined) {
        this.flood.timestamp = res.timestamp
        this.flood.id = res.id
        app.setSnackbar('氾濫地点を登録しました')
        this.floodPoints = []
      }
    } else {
      const timestamp = this.flood.timestamp
      const res = await Api.put(`/flood/${timestamp}`, this.flood)
      if (res !== null) {
        app.setSnackbar('氾濫地点を修正しました')
      }
    }
  }

  /*
  0: 解除
  1: 水防警報
  2: 氾濫注意
  3: 氾濫警戒
  4: 氾濫危険
  5: 氾濫発生
  */
  getFloodLevel(): number {
    if (this.isFlood()) {
      return 5
    } else if (this.isDanger()) {
      if (this.report.title.includes('氾濫警戒')) {
        return 3
      } else if (this.report.title.includes('氾濫危険')) {
        return 4
      } else {
        return 2
      }
    } else {
      if (this.report.title.includes('解除')) {
        return 0
      } else {
        return 1
      }
    }
  }

  async hold(): Promise<void> {
    this.report.category = this.categoryId
    const params: any = {
      mode: this.mode,
      osid: this.osid,
      report: this.report
    }
    let res: any
    if (this.isHold === true) {
      res = await Api.put('/stage-report/hold', params)
    } else {
      res = await Api.post('/stage-report/hold', params)
    }

    if (res === null) {
      app.setSnackbar('エラーが発生しました')
    } else {
      if (res.result === false) {
        if (this.isHold === true) {
          alert('保留データがみつかりません。\n削除もしくは、発表済みになった可能性があります。')
        } else {
          alert('別のユーザーが保留データを作成したため上書きできませんでした。')
        }
      } else {
        app.setSnackbar('保留しました')
        setTimeout(() => {
          this.$router.replace(`/stage/${this.mode}`)
        }, 500)
      }
    }
  }

  async send(): Promise<void> {
    let targetCount = 0
    const destinations = this.report.destinations
    Object.keys(destinations).forEach((key: string) => {
      const dest = destinations[key]
      if (dest.sendMail === true && dest.mail !== '') {
        targetCount++
      }
    })
    const params = {
      mode: this.mode,
      osid: this.osid,
      title: this.report.title
    }
    const checkRes = await Api.post(`/stage-report/check`, params)
    if (checkRes === null) {
      alert('通信エラー')
      return
    }
    if (checkRes.duplicate === true) {
      if (confirm('既に発表済みです。\nそのまま続行しますか？') !== true) {
        return
      }
    }

    if (targetCount === 0) {
      if (confirm('有効な宛先が選択されていません。そのまま送信しますか？') !== true) {
        return
      }
    }
    // if (this.isYahoo === false && (this.isDanger() || this.isFlood())) {
    //   if (this.getFloodLevel() >= 3) {
    //     if (confirm('Yahoo防災速報には発表しない設定になっていますが、このまま発表を実行しますか？') !== true) {
    //       return
    //     }
    //   }
    // }
    if (confirm('発表処理を開始します。よろしいですか？') !== true) {
      return
    }

    const jst = moment(this.sendDateTime)
    this.report.sendDateTime = SharedUtils.getDateTimeJp(jst.toDate())
    this.report.sendDateTimeUtc = jst.utc().format('YYYY/MM/DD HH:mm')

    this.report.category = this.categoryId
    this.report.floodLevel = this.getFloodLevel()

    const param = {
      mode: this.mode,
      report: this.report,
      isReportNumReset: this.isReportNumReset
    }
    const res = await Api.post('/stage-pdf/send', param)

    if (res === null) {
      app.setSnackbar('エラーが発生しました')
    } else {
      app.setSnackbar('発表しました')
      setTimeout(() => {
        this.$router.replace(`/stage/${this.mode}`)
      }, 500)
    }
  }
}
