






































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import moment from 'moment'
import { Api, Consts } from '@/utils'
import app from '@/store/app'
import { Report, ReportContent, SharedUtils } from 'gnm-pref-report-shared'
import { Datetime } from 'vue-datetime'
import ObservatoryHeader from '@/components/ObservatoryHeader.vue'

@Component({
  components: {
    ObservatoryHeader,
    vDatetime: Datetime
  }
})
export default class DamReport extends Vue {
  report: Report | null = null
  freeDialog = false
  section: '出水時' | '事前放流' = '出水時'
  categoryId: number = this.categories[0].id
  sendDateTime = moment().add(10, 'm').format('YYYY-MM-DDTHH:mm')
  freeInputContent: ReportContent | null = null
  freeInputArticle = ''
  telemeter?: {
    dateTime: number
    level: number
    qInAll: number
    qOutAll: number
    floodFlow: number
    levelDiff: number
    qInAllDiff: number
    qOutAllDiff: number
    capacityRate: number
    capacityRateDiff: number
    regularHeight: number
    forcast24: number
    limitOut: number
    assumedLevel: number
  }
  isYahoo = false
  isReportNumReset = false
  isReportNumResetVisible = true
  isHold = false

  get categories(): { id: number; name: string }[] {
    if (this.section === '出水時') {
      return [
        { id: 1, name: '通知1 洪水警戒体制発令の通知 (発令時点)' },
        { id: 2, name: '情報2 洪水警戒体制解除の情報 (解除時点)' },
        { id: 3, name: '通知3 越流開始の通知' },
        { id: 4, name: '通知4 放流量増加による急激な水位上昇の通知' },
        { id: 5, name: '情報5 洪水量に達した旨の速報' },
        { id: 6, name: '情報6 洪水量以下に低下' },
        { id: 7, name: '通知7 クレストからの越流予告の通知 (○時間前)' },
        { id: 8, name: '通知8 クレストからの越流予告の通知 (1時間前)' },
        { id: 9, name: '通知9 クレストからの越流開始の速報' },
        { id: 10, name: '情報10 クレストからの越流終了の速報' }
      ]
    } else {
      return [
        { id: 21, name: '事前1 事前放流体制の通知' },
        { id: 22, name: '事前2 事前放流体制解除の情報' },
        { id: 23, name: '事前3 事前放流1時間前の通知' },
        { id: 24, name: '事前4 事前放流開始の通知' },
        { id: 25, name: '事前5 事前放流中止の通知' },
        { id: 26, name: '事前6 洪水警戒体制の通知兼事前放流体制解除' }
      ]
    }
  }

  get mode(): string {
    return this.$route.params.mode
  }

  get isPractice(): boolean {
    return this.mode === Consts.MODE.practice
  }

  get osid(): string {
    return this.$route.params.osid
  }

  get reasons(): string[] {
    if (this.report === null) {
      return []
    }
    if (this.categoryId === 1) {
      return ['大雨洪水注意報', '大雨洪水警報', '台風', '低気圧', '前線', '融雪', 'その他']
    } else if (this.categoryId === 2) {
      return ['様式1', '様式2']
    } else if (this.categoryId === 3) {
      return ['事前放流', '常用洪水吐越流', 'その他']
    } else if (this.categoryId === 21) {
      return ['台風に関する気象情報', '大雨に関する全般気象情報', 'その他']
    } else if (this.categoryId === 22) {
      return ['台風に関する気象情報', '大雨に関する全般気象情報']
    } else if (this.categoryId === 26) {
      return ['大雨洪水注意報', '大雨洪水警報', '台風', '低気圧', '前線', '融雪', 'その他']
    } else {
      return []
    }
  }

  get reasonTitle(): string {
    if (this.report === null) {
      return ''
    }
    if (this.categoryId === 1) {
      return '洪水警戒体制に入った理由'
    } else if (this.categoryId === 2) {
      return '文言様式'
    } else if (this.categoryId === 3) {
      return '越流(放流)開始の目的'
    } else if (this.categoryId === 21 || this.categoryId === 22 || this.categoryId === 26) {
      return '事前放流体制に入った理由'
    } else {
      return ''
    }
  }

  get diffLabel(): string {
    switch (this.categoryId) {
      case 2:
      case 6:
      case 10:
        return '減少'
      default:
        return '増加'
    }
  }

  async created(): Promise<void> {
    await this.sync()
  }

  mounted(): void {
    //
  }

  async sync(): Promise<void> {
    const res = await Api.get(`/dam/one`, {
      mode: this.mode,
      osid: this.osid
    })

    const jst = moment().utc().add(9, 'hours')
    const currentDatetime = jst.format('M月D日H時m分')

    this.report = {
      osid: this.osid,
      timestamp: Date.now(),
      num: res.num,
      sendDateTime: SharedUtils.getDateTimeJp(),
      sendDateTimeUtc: moment().utc().format('YYYY/MM/DD HH:mm'),
      category: this.categories[0].id,
      observatory: res.observatory,
      office: res.office,
      title: '',
      contents: [],
      destinations: res.destinations,
      reason: '',
      sender: '〇〇〇',
      damInfo: {
        currentDatetime: currentDatetime,
        qInAll: '〇〇',
        qInAllDiff: '〇〇',
        qOutAll: '〇〇',
        qOutAllDiff: '〇〇',
        level: '〇〇',
        levelDiff: '〇〇',
        rate: '〇〇',
        rateDiff: '〇〇',
        riverCurrentDatetime: currentDatetime,
        riverPredictDatetime: '〇日〇時〇分頃',
        riverPredictLevel: '〇〇',
        riverLevel: '〇〇',
        rainForecast48Val: '〇〇',
        rainForecast48Time: '〇〇月〇〇日〇〇時',
        rainForecast24Val: '〇〇',
        rainForecast24Time: '〇〇月〇〇日〇〇時',
        startLevel: '〇〇.〇〇',
        planCapacity: '〇〇',
        adjustCapacity: '〇〇',
        provideCapacity: '〇〇',
        rainStation: '流域平均　〇〇観測所',
        rainMin60Val: '〇〇',
        rainMin60Time: '〇〇日〇〇時〜〇〇日〇〇時',
        rainTotalVal: '〇〇',
        rainTotalTime: '〇〇日〇〇時〜〇〇日〇〇時'
      }
    }
    this.isReportNumReset = res.num === 1
    this.isReportNumResetVisible = res.num > 1

    this.telemeter = res.telemeter
    if (this.telemeter !== undefined && this.report.damInfo !== undefined) {
      if (this.telemeter.qInAll !== undefined) {
        this.report.damInfo.qInAll = this.telemeter.qInAll.toFixed(2)
      }
      if (this.telemeter.qOutAll !== undefined) {
        this.report.damInfo.qOutAll = this.telemeter.qOutAll.toFixed(2)
      }
      if (this.telemeter.level !== undefined) {
        this.report.damInfo.level = this.telemeter.level.toFixed(2)
      }

      if (this.telemeter.qInAllDiff !== undefined) {
        this.report.damInfo.qInAllDiff = this.telemeter.qInAllDiff.toFixed(2)
      }
      if (this.telemeter.qOutAllDiff !== undefined) {
        this.report.damInfo.qOutAllDiff = this.telemeter.qOutAllDiff.toFixed(2)
      }
      if (this.telemeter.levelDiff !== undefined) {
        this.report.damInfo.levelDiff = this.telemeter.levelDiff.toFixed(2)
      }

      if (this.telemeter.capacityRate !== undefined) {
        this.report.damInfo.rate = this.telemeter.capacityRate.toFixed(0)
      }
      if (this.telemeter.capacityRateDiff !== undefined) {
        this.report.damInfo.rateDiff = this.telemeter.capacityRateDiff.toFixed(1)
      }
    }

    if (
      res.observatory.latestTimestamp === undefined ||
      res.observatory.latestTimestamp < moment().utc().add(-7, 'd').valueOf()
    ) {
      this.isReportNumReset = true
    }

    await this.changeCategory()

    // 保留データのロード
    const hold = await Api.get(`/dam-report/hold`, {
      mode: this.mode,
      osid: this.osid
    })
    if (hold === null || hold.report === null) {
      this.isHold = false
    } else {
      if (hold.report.hash !== undefined) {
        delete hold.report.hash
      }
      this.report = hold.report
      if (this.report !== null) {
        this.isHold = true
        this.categoryId = this.report.category === undefined ? this.categoryId : this.report.category
        app.setSnackbar('保留データを読み込みました')
      }
    }
  }

  async changeSection(): Promise<void> {
    this.categoryId = this.categories[0].id
    await this.changeCategory()
  }

  async changeCategory(): Promise<void> {
    console.log('changeCategory')
    if (this.report === null) {
      return
    }
    this.report.title = this.getTitleFromCategory()
    if (this.reasons.length > 0) {
      this.report.reason = this.reasons[0]
    }
    this.initReportContents()

    if (this.categoryId === 4 && this.report.damInfo !== undefined) {
      const res = await Api.get(`/docdb/public-point`, {
        osid: this.osid,
        date: moment.utc().format('YYYY/MM/DD HH:mm')
      })
      if (res !== null) {
        const publicTime = moment.utc(res.date, 'YYYY/MM/DD HH:mm').local().format('D日H時m分頃')
        this.report.damInfo.riverCurrentDatetime = publicTime
        this.report.damInfo.riverLevel = res.level
      }
    }
  }

  getTitleFromCategory(): string {
    if (this.report == null) {
      return ''
    }
    const obsname = this.report.observatory.name
    switch (this.categoryId) {
      case 1:
        return `${obsname}洪水警戒体制の通知`
      case 2:
        return `${obsname}洪水警戒体制解除の情報`
      case 3:
        return `${obsname}放流(越流)開始の通知`
      case 4:
        return `${obsname}放流量増加による急激な河川水位上昇の通知`
      case 5:
        return `${obsname}防災操作(洪水調節)開始の情報`
      case 6:
        return `${obsname}防災操作(洪水調節)終了の情報`
      case 7:
        return `重要通知 緊急放流 ○時間前`
      case 8:
        return `重要通知 緊急放流 １時間前`
      case 9:
        return `重要通知 緊急放流 開始`
      case 10:
        return `${obsname}緊急放流 終了の情報`
      case 21:
        return `${obsname}事前放流体制の通知`
      case 22:
        return `${obsname}事前放流体制解除の情報`
      case 23:
        return `${obsname}事前放流 1時間前の通知`
      case 24:
        return `${obsname}事前放流 開始の通知`
      case 25:
        return `${obsname}事前放流 中止の通知`
      case 26:
        return `${obsname}洪水警戒体制の通知 兼 ${obsname}事前放流体制解除の情報`
      default:
        return ''
    }
  }

  getObsPlace(address: string | undefined): string {
    if (address === undefined) {
      return ''
    }
    if (address.indexOf('市') > -1) {
      return '(' + address.substring(0, address.indexOf('市') + 1) + ')'
    } else if (address.indexOf('郡') > -1) {
      return '(' + address.substring(0, address.indexOf('郡') + 1) + ')'
    } else {
      return ''
    }
  }

  initReportContents(): void {
    if (this.report === null) {
      return
    }
    this.report.contents = []

    const jst = moment(this.sendDateTime)
    const ymdhm = jst.format('M月D日H時m分')

    const now = moment().utc().add(9, 'hours').format('M月D日H時m分')
    const nowAdd1Hours = moment().utc().add(10, 'hours').format('M月D日H時m分')

    const place = this.getObsPlace(this.report.observatory.address)
    const fullname = `利根川水系${this.report.observatory.river}${this.report.observatory.name}${place}`
    const content1: ReportContent = {
      title: 'ダム操作に関する通知',
      article: ''
    }

    let qOutAll = '〇〇.〇'
    let floodFlow = '〇〇'
    let regularHeight = '〇〇'
    let limitOut = '〇〇'
    let assumedLevel = '〇〇'
    if (this.telemeter !== undefined) {
      qOutAll = this.telemeter.qOutAll !== undefined ? this.telemeter.qOutAll.toFixed(2) : qOutAll
      floodFlow = this.telemeter.floodFlow !== undefined ? this.telemeter.floodFlow.toFixed(2) : floodFlow
      regularHeight =
        this.telemeter.regularHeight !== undefined ? this.telemeter.regularHeight.toFixed(1) : regularHeight
      limitOut = this.telemeter.limitOut !== undefined ? String(this.telemeter.limitOut) : limitOut
      assumedLevel = this.telemeter.assumedLevel !== undefined ? this.telemeter.assumedLevel.toFixed(1) : assumedLevel
    }

    // 文章1
    switch (this.categoryId) {
      case 1:
        content1.article =
          `${fullname}では、${ymdhm}に洪水警戒体制に入りました。\n` +
          `流入が増加し、ダムからの放流を含めて急激に下流河川の水位が上昇することがあります。\n` +
          `今後の降雨状況やダム放流状況に注意してください。`
        break
      case 2:
        content1.article = `${fullname}では、${ymdhm}に洪水警戒体制を解除しました。\n`
        if (this.report.reason === '様式2') {
          content1.article += `雨量及び下流河川水位の状況から、ダムからの情報提供は終了します。`
        } else {
          content1.article += `ダムへの流入量は洪水量以下に減少しており、雨量及び下流河川水位の状況から今回の出水に関してダムからの情報提供は終了します。`
        }
        break
      case 3:
        if (this.report.reason === '常用洪水吐越流') {
          content1.article =
            `${fullname}では、水位が常用洪水吐敷高(EL.${regularHeight}m)を超え、常用洪 水吐から越流を開始しダム流下量(放流量)が〇〇㎥/s に増加する見込みです。\n` +
            `下流河川の水位上昇に注意してください。`
        } else {
          content1.article = `${fullname}では、${ymdhm}から〇〇㎥/s の放流を開始します。`
        }
        break
      case 4:
        content1.article =
          `${fullname}では、ダム流下量(放流量)が${qOutAll}㎥/s から〇〇.〇㎥/s に増加する予定です。\n` +
          `下流河川の水位上昇に注意してください。\n` +
          `また、河川内へ立ち入らないように注意してください。`
        break
      case 5:
        content1.article =
          `${fullname}では、ダムへの流入量が洪水量(${floodFlow}㎥/s)に達したため、${now}に防災操作(洪水調節)を開始しました。\n` +
          `今後、防災操作(洪水調節)終了まで流入量の一部がダムから越流し、残りをダムに貯留します。 ダム流下量(放流量)は徐々に増加します。`
        break
      case 6:
        content1.article =
          `${fullname}では、ダムへの流入量がダム流下量(放流量)を下回ったため、${now}に防災操作(洪水調節)が終了しました。\n` +
          `今後、現在のダム流下量(放流量)を上限として越流が継続し、河川水位は徐々に低下して いきます。\n` +
          `防災操作(洪水調節)は終了しましたが、河川水位が平常時の状況に回復するまで、引き続き河川水位に注意してください。`
        break
      case 7:
        content1.article =
          `${fullname}では、現在、防災操作(洪水調節)を行っています。\n` +
          `今後、計画規模を超える洪水が予想されるため、ダムに水を貯められなくなり、${nowAdd1Hours}頃から緊急放流（非常用洪水吐からの越流）となり、下流に流れる水量が増えるおそれがあります。\n` +
          `そのため、洪水氾濫のおそれがあります。\n` +
          `非常用洪水吐から越流するおおむね１時間前にも事前通知をしますので、ダムからの連絡等に注意してください。\n` +
          `※ 今後の降雨状況により時間が前後する可能性がありますので、ご注意ください。`
        break
      case 8:
        content1.article =
          `${fullname}では、現在、防災操作(洪水調節)を行っていますが、防災操作(洪水調節)に使用できるダムの空容量が減少しています。\n` +
          `今後、計画規模を超える洪水が予想されるため、ダムに水を貯められなくなり、${nowAdd1Hours}頃から緊急放流（非常用洪水吐からの越流）となり、下流に流れる水量が増えるおそれがあります。\n` +
          `そのため、洪水氾濫のおそれがあります。\n` +
          `非常用洪水吐から越流した場合は、ただちにその旨を通知します。\n` +
          `※ 今後の降雨状況により時間が前後する可能性がありますので、ご注意ください。`
        break
      case 9:
        content1.article = `${fullname}では、計画規模を超える洪水のため、${now}に緊急放流（非常用洪水吐からの越流）となりました。`
        break
      case 10:
        content1.article =
          `${fullname}では、緊急放流（非常用洪水吐からの越流）が${now}に終了しました。\n` +
          `今後、ダムからの放流量は減少していきますが、河川水位は引き続き高い状況が続きますので、注意してください。`
        break
      case 21:
        content1.article =
          `${fullname}では、${ymdhm}に事前放流の体制に入りました。\n` +
          `今後、予測降雨量が基準降雨量を上回った場合は、事前放流を実施します。`
        break
      case 22:
        content1.article =
          `${fullname}では、${ymdhm}に事前放流体制を解除しました。\n` +
          `ダムの施設能力を上回る洪水が発生する見込みは無くなりましたが、` +
          `引き続き${this.report.reason}を注視し、雨量、流入量及び貯水位の状況に応じて、ダムの運用管理を実施します。\n` +
          `今後もダムからの通知等に注意して下さい。`
        break
      case 23:
        content1.article =
          `${fullname}では、${ymdhm}から段階的に最大${limitOut}m3/sまでの事前放流を実施します。\n\n` +
          `下流河川の水位が段階的に上昇しますのでご注意ください。`
        break
      case 24:
        content1.article =
          `${fullname}では、${ymdhm}から段階的に最大${limitOut}m3/sまでの事前放流を開始しました。\n\n` +
          `下流河川の水位が段階的に上昇しますのでご注意ください。`
        break
      case 25:
        content1.article =
          `${fullname}では、予測降雨量が基準降雨量を下回ったため、${ymdhm}に事前放流を中止しました。\n\n` +
          `今後は、事前放流開始時の貯水位への回復に努めるとともに、雨量、流入量及び貯水位の状況に応じて、ダムの運用管理を実施します。\n` +
          `引き続き、ダムからの通知等に注意してください。`
        break
      case 26:
        content1.article =
          `${fullname}では、${ymdhm}に事前放流体制を解除し、洪水警戒体制に移行しました。\n` +
          `流入量が増加し、ダムからの放流を含めて急激に下流河川の水位が上昇することがあります。\n` +
          `今後の降雨状況やダム放流状況に注意して下さい。`
        break
      default:
        break
    }
    this.report.contents.push(content1)

    // 文章2
    if (this.categoryId === 1) {
      const reason = this.report.reason === 'その他' ? '〇〇' : this.report.reason
      this.report.contents.push({
        title: '洪水警戒体制に入った理由',
        article:
          reason === '大雨洪水注意報' || reason === '大雨洪水警報'
            ? `${reason}が発表された。  発表時刻：${ymdhm}`
            : `${reason}により洪水が予測されるため。`
      })
    } else if (this.categoryId === 3) {
      const content2: ReportContent = {
        title: '越流(放流)開始の目的',
        article: ''
      }
      if (this.report.reason === '常用洪水吐越流') {
        content2.article = `常用洪水吐敷高 EL.${regularHeight}m を超え、常用洪水吐から越流を開始します。常用洪水吐敷高からサーチャージ水位までの空容量を活用し防災操作(洪水調節)を行います。`
      } else {
        content2.article =
          '今後の防災操作(洪水調節)に備えて、ダム水位を EL.〇〇.〇m に低下させ、防災操作(洪水調節)に活用する空容量を拡大する。'
      }
      this.report.contents.push(content2)
    } else if (this.categoryId === 21) {
      const reason = this.report.reason === 'その他' ? '〇〇' : this.report.reason
      this.report.contents.push({
        title: '事前放流体制に入った理由',
        article: `${reason}によりダムの施設能力を上回る洪水の発生が予測されるため。`
      })
    } else if (this.categoryId === 23 || this.categoryId === 24) {
      this.report.contents.push({
        title: '放流開始の目的',
        article: `今後の洪水調節に備えて、貯水位をEL.${assumedLevel}mに低下させ、洪水調節に活用する空容 量を拡大する。`
      })
    } else if (this.categoryId === 26) {
      const reason = this.report.reason === 'その他' ? '〇〇' : this.report.reason
      this.report.contents.push({
        title: '洪水警戒体制に入った理由',
        article:
          reason === '大雨洪水注意報' || reason === '大雨洪水警報'
            ? `${reason}が発表された。  発表時刻：${ymdhm}`
            : `${reason}により洪水が予測されるため。`
      })
    }
  }

  showFreeDialog(content: ReportContent): void {
    if (content === null) {
      return
    }
    this.freeInputContent = content
    this.freeInputArticle = content.article
    this.freeDialog = true
  }

  saveFreeDialog(): void {
    if (this.freeInputContent === null || this.freeInputContent.article === undefined) {
      return
    }
    this.freeInputContent.article = this.freeInputArticle
    this.freeDialog = false
  }

  async hold(): Promise<void> {
    if (this.report === null) {
      return
    }
    this.report.category = this.categoryId
    const params: any = {
      mode: this.mode,
      osid: this.osid,
      report: this.report
    }
    let res: any
    if (this.isHold === true) {
      res = await Api.put('/dam-report/hold', params)
    } else {
      res = await Api.post('/dam-report/hold', params)
    }

    if (res === null) {
      app.setSnackbar('エラーが発生しました')
    } else {
      if (res.result === false) {
        if (this.isHold === true) {
          alert('保留データがみつかりません。\n削除もしくは、発表済みになった可能性があります。')
        } else {
          alert('別のユーザーが保留データを作成したため上書きできませんでした。')
        }
      } else {
        app.setSnackbar('保留しました')
        setTimeout(() => {
          this.$router.replace(`/dam/${this.mode}`)
        }, 500)
      }
    }
  }

  async pdf(): Promise<void> {
    if (this.report === null) {
      return
    }
    const jst = moment(this.sendDateTime)
    this.report.sendDateTime = SharedUtils.getDateTimeJp(jst.toDate())
    this.report.sendDateTimeUtc = jst.utc().format('YYYY/MM/DD HH:mm')
    this.report.category = this.categoryId
    const param = {
      mode: this.mode,
      report: this.report
    }
    param.report.num = this.isReportNumReset === true ? 1 : this.report.num
    const res = await Api.post('/dam-pdf/spot', param)
    if (res === undefined || res === null || res.url === undefined) {
      return
    }
    window.open(res.url, '_blank')
  }

  async send(): Promise<void> {
    if (this.report === null) {
      return
    }
    let targetCount = 0
    const destinations = this.report.destinations
    Object.keys(destinations).forEach((key: string) => {
      const dest = destinations[key]
      if (dest.sendMail === true && dest.mail !== '') {
        targetCount++
      }
    })
    const params = {
      mode: this.mode,
      osid: this.osid,
      title: this.report.title
    }
    const checkRes = await Api.post(`/dam-report/check`, params)
    if (checkRes === null) {
      alert('通信エラー')
      return
    }
    if (checkRes.duplicate === true) {
      if (confirm('既に発表済みです。\nそのまま続行しますか？') !== true) {
        return
      }
    }

    if (targetCount === 0) {
      if (confirm('有効な宛先が選択されていません。そのまま送信しますか？') !== true) {
        return
      }
    }
    if (confirm('発表処理を開始します。よろしいですか？') !== true) {
      return
    }
    const jst = moment(this.sendDateTime)
    this.report.sendDateTime = SharedUtils.getDateTimeJp(jst.toDate())
    this.report.sendDateTimeUtc = jst.utc().format('YYYY/MM/DD HH:mm')

    this.report.category = this.categoryId

    const param = {
      mode: this.mode,
      report: this.report,
      isReportNumReset: this.isReportNumReset
    }
    const res = await Api.post('/dam-pdf/send', param)

    if (res === null) {
      app.setSnackbar('エラーが発生しました')
    } else {
      app.setSnackbar('発表しました')
      setTimeout(() => {
        this.$router.replace(`/dam/${this.mode}`)
      }, 500)
    }
  }
}
