import '@babel/polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueCookies from 'vue-cookies'
import * as VueGoogleMaps from 'vue2-google-maps'
import { Consts } from '@/utils/consts'
import 'vue-datetime/dist/vue-datetime.css'

// vue-cookies
Vue.use(VueCookies)
Vue.$cookies.config('8h')

// vue2-google-maps
Vue.use(VueGoogleMaps, {
  load: {
    key: Consts.GOOGLE_MAP_KEY,
    libraries: 'places'
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
