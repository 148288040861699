





































































































import { Component, Vue } from 'vue-property-decorator'
import { Destination } from 'gnm-pref-report-shared'
import { MlitSection } from '@/types'
import { Common, Consts, Api } from '@/utils'
import app from '@/store/app'

@Component({
  components: {}
})
export default class MlitReport extends Vue {
  osid = ''
  subject = ''
  pdfUrl = ''
  destinations: Destination[] = []

  get section(): MlitSection {
    return this.$route.params.section === 'dam' ? 'dam' : 'stage'
  }

  get timestamp(): number {
    return Number(this.$route.params.timestamp)
  }

  get backToUrl(): string {
    return `/mlit/top/${this.section}`
  }

  async created(): Promise<void> {
    await this.sync()
  }

  mounted(): void {
    //
  }

  preview(): void {
    window.open(this.pdfUrl, '_blank')
  }

  async sync(): Promise<void> {
    const res = await Api.get(`/mlit/report/${this.section}/${this.timestamp}`, {})
    if (res === null) {
      alert('データの取得に失敗しました')
      this.$router.replace(this.backToUrl)
    }
    this.osid = res.osid
    this.subject = res.subject
    this.destinations = res.destinations
    this.pdfUrl = `${Consts.S3_PUBLIC_URL}/${res.attachmentS3Key}#toolbar=0&navpanes=0`
  }

  async forward(): Promise<void> {
    const destinations = this.destinations
    const finded = Object.values(destinations).find((dest: Destination) => dest.sendMail === true && dest.mail !== '')
    if (finded === undefined) {
      if (confirm('有効な宛先が選択されていません。そのまま送信しますか？') !== true) {
        return
      }
    }
    if (confirm('転送処理を開始します。よろしいですか？') !== true) {
      return
    }
    const param = {
      section: this.section,
      timestamp: this.timestamp,
      destinations: this.destinations
    }
    const res = await Api.post('/mlit/forward', param)
    if (res === null) {
      app.setSnackbar('エラーが発生しました')
      return
    }
    if (res.duplicate === true) {
      alert('既に転送済みです。')
      this.$router.replace(`/mlit/history/${this.section}/${this.osid}`)
      return
    }
    if (res !== null && res.forwarded === true) {
      app.setSnackbar('発表しました')
      setTimeout(() => {
        this.$router.replace(this.backToUrl)
      }, 500)
    } else {
      app.setSnackbar('エラーが発生しました')
    }
  }
}
