












import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import app from '@/store/app'
import { SortParam } from '@/types'

@Component
export default class SortBtn extends Vue {
  private isDesc = true

  @Prop({ type: Boolean, default: false })
  asc!: boolean

  @Prop({ required: true })
  field!: string

  mounted(): void {
    this.isDesc = !this.asc
  }

  @Emit('select')
  select(): SortParam {
    if (this.current === this.field) {
      this.isDesc = !this.isDesc
    } else {
      app.setSortField(this.field)
    }
    return {
      field: this.field,
      desc: this.isDesc
    }
  }

  get current(): string {
    return app.sortField
  }
}
