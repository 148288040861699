import { Mutation, VuexModule, getModule, Module } from 'vuex-module-decorators'
import store from '@/store'

@Module({
  dynamic: true,
  store,
  name: 'app',
  namespaced: true
})
class App extends VuexModule {
  loading = false
  mapTab: Window | null = null
  sortField = ''
  snackbar = ''

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading
  }
  @Mutation
  setMapTab(mapTab: Window) {
    this.mapTab = mapTab
  }
  @Mutation
  setSortField(sortField: string) {
    this.sortField = sortField
  }
  @Mutation
  setSnackbar(snackbar: string) {
    this.snackbar = snackbar
  }
}
export default getModule(App)
