


























































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import moment from 'moment'
import { Api } from '@/utils/api'
import { Common } from '@/utils/common'
import { Consts } from '@/utils/consts'
import app from '@/store/app'
import StageChip from '@/components/StageChip.vue'
import { Observatory } from 'gnm-pref-report-shared'
import SortBtn from '@/components/SortBtn.vue'
import { SortParam } from '@/types'
import auth from '@/store/auth'
import { Datetime as VueDatetime } from 'vue-datetime'

interface Row extends Observatory {
  status: string
  checked: boolean
  current: number
  stageLevel: number
  transition: string
  latestLevel: number
  history: string
  latestReport?: string
  basicOrder: number // 現況水位の並び順
  hold?: boolean
}

@Component({
  components: {
    StageChip,
    SortBtn,
    VueDatetime
  }
})
export default class Stage extends Vue {
  rows: Row[] = []
  baseRows: Row[] = []
  headerHeight = 0
  footerHeight = 60
  tableHeight: number | 'auto' = 'auto'

  offices: string[] = []
  rivers: string[] = []
  cities: string[] = []

  showedExportDialog = false
  exportDate1 = moment().subtract(1, 'month').format('YYYY-MM-DDTHH:00')
  exportDate2 = moment().add(1, 'hours').format('YYYY-MM-DDTHH:00')

  readonly FILTER_NONE = '全て'
  selectedOffice = this.FILTER_NONE
  selectedRiver = this.FILTER_NONE
  selectedCity = this.FILTER_NONE

  sortParam: SortParam = {
    field: '現況水位',
    desc: true
  }

  isBulkIndeterminate = false
  isBulkCheck = false

  get isKasenka(): boolean {
    return auth.isKasenka
  }

  get mode(): string {
    return this.$route.params.mode
  }

  get isPractice(): boolean {
    return this.mode === Consts.MODE.practice
  }

  @Watch('mode')
  changeMode(): void {
    this.sync()
  }

  created(): void {
    app.setSortField('現況水位')
    this.sync()
    window.addEventListener('resize', this.resize)
  }

  destroyed(): void {
    window.removeEventListener('resize', this.resize)
  }

  mounted(): void {
    const stageHeader = this.$refs.stage_header as Element
    if (stageHeader === undefined) {
      this.headerHeight = 0
    } else {
      this.headerHeight = stageHeader.clientHeight
    }
    this.resize()
  }

  isOwner(officeId: string | undefined): boolean {
    if (officeId === undefined || auth.account === undefined) {
      return false
    }
    return this.isKasenka || officeId === auth.account?.officeId
  }

  resize(): void {
    if (this.headerHeight <= 0) {
      this.tableHeight = 'auto'
    } else {
      this.tableHeight = window.innerHeight - Consts.APP_BAR_HEIGHT - this.headerHeight - this.footerHeight
    }
  }

  async sync(): Promise<void> {
    this.isBulkCheck = false
    this.isBulkIndeterminate = false
    this.baseRows = []
    const params = {
      mode: this.mode
    }
    const response: Row[] = await Api.get(`/stage/list`, params)
    for (const row of response) {
      let stageLevel = 0
      if (row.dangerousLevel !== undefined && row.dangerousLevel <= row.current) {
        stageLevel = 4
      } else if (row.evacuationLevel !== undefined && row.evacuationLevel <= row.current) {
        stageLevel = 3
      } else if (row.warningLevel !== undefined && row.warningLevel <= row.current) {
        stageLevel = 2
      } else if (row.standbyLevel !== undefined && row.standbyLevel <= row.current) {
        stageLevel = 1
      }

      // 現況水位の並び順(小さいほど上位)
      if (row.standbyLevel === undefined || row.current === -999) {
        row.basicOrder = 1000
      } else {
        const val = Number(row.standbyLevel - row.current)
        row.basicOrder = isNaN(val) ? 1000 : val
      }

      row.checked = false
      row.status = row.hold === undefined || row.hold !== true ? '新規' : '保留'
      row.stageLevel = stageLevel
      row.latestLevel = row.latestLevel === undefined ? -1 : row.latestLevel
      row.history = row.history === '' || row.history === undefined ? '履歴' : row.history
      this.baseRows.push(row)
    }

    if (this.offices.length === 0) {
      this.offices = this.baseRows.map((row: Row) => (row.officeName === undefined ? '' : row.officeName))
      this.offices = Array.from(new Set(this.offices))
      this.offices.unshift(this.FILTER_NONE)
    }
    if (this.rivers.length === 0) {
      this.rivers = this.baseRows.map((row: Row) => (row.river === undefined ? '' : row.river))
      this.rivers = Array.from(new Set(this.rivers))
      this.rivers.unshift(this.FILTER_NONE)
    }
    if (this.cities.length === 0) {
      this.cities = this.baseRows.map((row: Row) => (row.city === undefined ? '' : row.city))
      this.cities = Array.from(new Set(this.cities))
      this.cities.unshift(this.FILTER_NONE)
    }
    this.filterRows()
  }

  filterRows(): void {
    this.isBulkCheck = false
    this.isBulkIndeterminate = false
    this.checkBulk()
    this.rows = this.baseRows.filter((row: Row) => {
      if (this.selectedOffice !== this.FILTER_NONE && row.officeName !== this.selectedOffice) {
        return false
      }
      if (this.selectedRiver !== this.FILTER_NONE && row.river !== this.selectedRiver) {
        return false
      }
      if (this.selectedCity !== this.FILTER_NONE && row.city !== this.selectedCity) {
        return false
      }
      return true
    })
    this.sort(this.sortParam)
  }

  getCurrentStage(): number {
    const num = Math.floor(Math.random() * 300)
    return num / 100
  }

  getFloodColor(floodLevel: number): string {
    return Common.getFloodLevelColor(floodLevel)
  }

  showMap(row: Row): void {
    if (row.lat === undefined || row.lng === undefined) {
      return
    }
    Common.showMap(row.lat, row.lng)
  }

  sort(sortEvent: { field: string; desc: boolean }): void {
    this.sortParam = sortEvent
    let order = []
    const reverse = sortEvent.desc
    switch (sortEvent.field) {
      case '事務所':
        order = [
          { key: 'officeName', reverse: reverse },
          { key: 'stageLevel', reverse: true },
          { key: 'basicOrder', reverse: false },
          { key: 'river', reverse: false },
          { key: 'name', reverse: false }
        ]
        break
      case '河川':
        order = [
          { key: 'river', reverse: reverse },
          { key: 'stageLevel', reverse: true },
          { key: 'basicOrder', reverse: false },
          { key: 'officeName', reverse: false },
          { key: 'name', reverse: false }
        ]
        break
      default:
        order = [
          { key: 'stageLevel', reverse: !reverse },
          { key: 'basicOrder', reverse: !reverse },
          { key: 'officeName', reverse: false },
          { key: 'river', reverse: false }
        ]
        break
    }
    this.rows.sort(Common.compareHelper(order))
  }

  async deleteHistory(): Promise<void> {
    const checked = this.rows.filter((row: Row) => {
      return row.checked === true && row.latestLevel >= 0 && this.isOwner(row.officeId) === true
    })
    if (checked.length === 0) {
      this.rows.forEach((row: Row) => {
        row.checked = false
        this.isBulkCheck = false
        this.isBulkIndeterminate = false
      })
      return
    }
    if (confirm('発表状況を消去します。\n処理を実行してもよろしいですか？\n(履歴には残ります)') !== true) {
      return
    }
    await Promise.all(
      checked.map(async (row: Row) => {
        const params = {
          mode: this.mode,
          osid: row.id
        }
        await Api.delete(`/stage-history/latest`, params)
      })
    )

    await this.sync()
  }

  async deleteHold(): Promise<void> {
    const checked = this.rows.filter((row: Row) => {
      return row.checked === true && row.hold !== undefined && row.hold === true && this.isOwner(row.officeId) === true
    })
    if (checked.length === 0) {
      this.rows.forEach((row: Row) => {
        row.checked = false
        this.isBulkCheck = false
        this.isBulkIndeterminate = false
      })
      return
    }
    if (confirm('作成中の文章を破棄します。\n処理を実行してもよろしいですか？') !== true) {
      return
    }
    await Promise.all(
      checked.map(async (row: Row) => {
        const params = {
          mode: this.mode,
          osid: row.id
        }
        await Api.delete(`/stage-report/hold`, params)
      })
    )

    await this.sync()
  }

  showFlood(row: Row): void {
    this.$router.push({
      path: '/flood',
      query: {
        osname: row.name
      }
    })
    row.name
  }

  checkBulk(): void {
    this.isBulkIndeterminate = false
    this.rows.forEach((row: Row) => {
      row.checked = this.isBulkCheck
    })
  }

  checkRow(): void {
    const checked = this.rows.filter((row: Row) => {
      return row.checked === true
    })
    if (checked.length === 0) {
      this.isBulkCheck = false
      this.isBulkIndeterminate = false
    } else if (this.rows.length === checked.length) {
      this.isBulkCheck = true
      this.isBulkIndeterminate = false
    } else {
      this.isBulkIndeterminate = true
    }
  }

  async exportHistory(): Promise<void> {
    const convertUtc = (date: string) => {
      const jst = moment(date)
      return jst.subtract(9, 'hours').format('YYYY/MM/DD HH:mm')
    }
    let date1 = convertUtc(this.exportDate1)
    let date2 = convertUtc(this.exportDate2)
    if (date1 > date2) {
      const tmp = date1
      date1 = date2
      date2 = tmp
    }
    const res = await Api.get('/excel/stage-history', {
      mode: this.mode,
      date1: date1,
      date2: date2
    })
    if (res.s3 !== undefined) {
      const url = `${Consts.S3_PUBLIC_URL}/${res.s3}`
      window.open(url, '_blank')
    }
  }
}
